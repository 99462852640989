import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Alert } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import { axiosInstance } from '../../helpers/axios';
import env from '../../env';

//Import Breadcrumb
import Breadcrumbs from '../../components/ThemeCMBP/Partials/Breadcrumb';

import InfoPart from '../../components/ThemeCMBP/Profile/InfoPart';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

//i18n
import { withNamespaces } from 'react-i18next';
import NewPassword from '../../components/ThemeCMBP/Profile/NewPassword';

class profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: null,
            isLoading: true,
            formData: null,
            errorMessage: null,
            successMessage: null,
            hideNewForm: true,
            hideEditForm: true,
            currentCompanyType: "",
            infoFacturations: null,
            deleteMessage: null,
            textMessage: null,
            hideDeleteButtons: false,
            hideTable: false,
            displayDeleteMessage: null,
            IFListColumns: [
                {
                    dataField: '_nom_societe',
                    text: 'Société/Structure',
                    sort: true,
                    formatter: (value, row) => <p>{value}</p>
                },
                {
                    dataField: '_num_tva_communautaire',
                    text: 'Numéro de TVA',
                    sort: true,
                    formatter: (value, row) => <p>{value}</p>
                },
                {
                    dataField: '_civilite',
                    text: 'Civilite',
                    sort: true,
                    formatter: (value, row) => <p>{value[0].toUpperCase() + value.slice(1)}</p>
                },
                {
                    dataField: '_nom',
                    text: 'Nom',
                    sort: true,
                    formatter: (value, row) => <p>{value}</p>
                },
                {
                    dataField: '_prenom',
                    text: 'Prénom',
                    sort: true,
                    formatter: (value, row) => <p>{value}</p>
                },
                {
                    dataField: '_adresse',
                    text: 'Adresse',
                    sort: true,
                    formatter: (value, row) => <p>{value}</p>
                },
                {
                    dataField: '_code_postal',
                    text: 'Code Postal',
                    sort: true,
                    formatter: (value, row) => <p>{value}</p>
                },
                {
                    dataField: '_ville',
                    text: 'Ville',
                    sort: true,
                    formatter: (value, row) => <p>{value}</p>
                },
                {
                    dataField: 'menu',
                    text: 'Action',
                    formatter: (cellContent, IF) => (
                        <div className='d-flex gap-3'>
                            <div className='d-flex'>
                                <Button
                                    href='#'
                                    className='btn btn-sm btn-info mr-3'
                                    onClick={() => this.handleEdit(IF._id)}
                                >
                                    Modifier
                                </Button>
                                <Button
                                    href='#'
                                    className='btn btn-sm btn-danger'
                                    onClick={() => this.handleDelete(IF._id)}
                                >
                                    Supprimer
                                </Button>
                            </div>
                        </div>
                    )
                }
            ]
        };
        this.handleNewFormSubmit = this.handleNewFormSubmit.bind(this);
        this.handleEditFormSubmit = this.handleEditFormSubmit.bind(this);
        this.updateCurrentCompanyType = this.updateCurrentCompanyType.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.cancelDelete = this.cancelDelete.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
    }

    updateCurrentCompanyType(event){
        this.setState({currentCompanyType: event.currentTarget.value})
    }

    checkTVA(data): Boolean {
        return new Promise(resolve => {
            let cle = data.num_tva_communautaire.substring(2, 4);
            let reste = data.num_tva_communautaire.substring(4);
            let calcul = 12 + ((3 * (reste % 97)) % 97);
            setTimeout(() => {
                if (cle == calcul) {
                    resolve(true);
                } else {
                    this.setState({
                        errorMessage: true,
                        successMessage: false,
                        displayDeleteMessage: false,
                        textMessage: "Votre numéro de TVA intracommunautaire n'est pas valide",
                    });
                    resolve(false)
                }
            }, 500);
        });
    }

    async checkNewForm(event, values) {
        if(values.num_tva_communautaire!= null && values.num_tva_communautaire != undefined)
            await this.checkTVA(values);    
        this.handleNewFormSubmit(event, values);
    }

    handleNewFormSubmit(event, values) {
        axiosInstance
            .post(env.REACT_APP_API_URL + '/billing/info', values)
            .then(response => {
                this.getInfoFacturationByUser();
                this.setState({
                    errorMessage: false,
                    successMessage: true,
                    displayDeleteMessage: false,
                    textMessage: 'Vos nouvelles informations de facturation ont bien été enregistées.',
                    hideNewForm: true,
                    hideEditForm: true,
                });
                if (response.status === 400 || response.status === 500) {
                    this.setState({
                        errorMessage: true,
                        successMessage: false,
                        displayDeleteMessage: false,
                        textMessage:
                            "Il y a eu un problème lors de l'enregistrement de vos informations de facturation. Merci de rééssayer ultrérieurement.",
                        hideNewFormCompany: !(values.type == "company"),
                        hideNewFormPerson: !(values.type == "person")
                    });
                    throw response.data;
                }
                return response.data;
            })
            .catch(err => {
                console.log(err);
            });
    }

    async handleEditFormSubmit(event, values) {
        let data = {};
        Object.entries(values).forEach(([key, value]) => {
            if (key !== 'id_user' && key !== 'id') {
                data[key.substring(1)] = value;
            } else {
                data[key] = value;
            }
        });
        let tva = true
        if (values._num_tva_communautaire != null && values._num_tva_communautaire != undefined)
            tva = await this.checkTVA({ "num_tva_communautaire": values._num_tva_communautaire });
        if (values != null && tva == true) {
            axiosInstance
                .put(env.REACT_APP_API_URL + '/billing/info/' + data.id, data)
                .then(response => {
                    this.getInfoFacturationByUser();
                    this.setState({
                        errorMessage: false,
                        successMessage: true,
                        displayDeleteMessage: false,
                        textMessage: 'Vos informations de facturation ont bien été mise à jour',
                        hideNewForm: true,
                        hideEditForm: true,
                    });
                    if (response.status === 400 || response.status === 500) {
                        this.setState({
                            errorMessage: true,
                            successMessage: false,
                            displayDeleteMessage: false,
                            textMessage:
                                "Il y a eu un problème lors de l'enregistrement de vos informations de facturation. Merci de rééssayer ultrérieurement.",
                                hideNewForm: true,
                                hideEditForm: true,
                        });
                        throw response.data;
                    }
                    return response.data;
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }

    handleEdit(values) {
        this.setState({
            formData: null,
            hideNewForm: true,
            hideEditForm: true,
            errorMessage: false,
            successMessage: false,
            displayDeleteMessage: false
        });
        axiosInstance
            .get(env.REACT_APP_API_URL + '/billing/info/' + values)
            .then(res => {
                this.setState({
                    formData: res.data, 
                    currentCompanyType: (res.data._type == "person" || res.data._type == "company") ? res.data._type : "other",
                    hideEditForm: false,
                });
                console.log(this.state.currentCompanyType)
            })
            .catch(err => {
                console.log(err);
            });
    }

    handleDelete(values) {
        this.setState({
            errorMessage: false,
            successMessage: false,
            displayDeleteMessage: true,
            textMessage: 'Voulez-vous vraiment supprimer cette information de facturation ?',
            targetId: values
        });
    }

    cancelDelete() {
        this.setState({ errorMessage: false, successMessage: false, displayDeleteMessage: false, hideDeleteButtons: false, targetId: null });
    }

    confirmDelete() {
        axiosInstance
            .delete(env.REACT_APP_API_URL + '/billing/info/' + this.state.targetId)
            .then(res => {
                this.getInfoFacturationByUser();
                this.setState({
                    textMessage: "L'information de facturation sélectionnée a bien été supprimé",
                    errorMessage: false,
                    successMessage: true,
                    displayDeleteMessage: false,
                    targetId: null
                });
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    textMessage: "Une erreur est survenue durant la tentative de suppression. Il est possible qu'une facture soit rattaché à cette information, empêchant sa suppression.",
                    errorMessage: true,
                    successMessage: false,
                    displayDeleteMessage: true,
                    hideDeleteButtons: true
                });
            });
    }

    getInfoFacturationByUser() {
        axiosInstance
            .get(env.REACT_APP_API_URL + '/billing/info/user/' + this.state.user._id)
            .then(res => {
                this.setState({ infoFacturations: res.data, isLoading: false, hideNewForm: res?.data.length !=0});
            })
            .catch(err => {
                console.log(err);
            });
    }

    componentDidMount() {
        axiosInstance
            .get(env.REACT_APP_API_URL + '/user/profile')
            .then(res => {
                axiosInstance.get(env.REACT_APP_API_URL + '/user/' + res.data.userId).then(res => {
                    this.setState({ user: res.data });
                    this.getInfoFacturationByUser();
                });
            })
            .catch(err => {
                this.setState({ errorMessage: err.message });
            });
    }

    render() {
        if (this.state.isLoading) {
            return <p>Loading ...</p>;
        } else {
            const { user, infoFacturations } = this.state;
            const { SearchBar } = Search;

            const pageOptions = {
                sizePerPage: 10,
                totalSize: infoFacturations.length,
                custom: true
            };

            const defaultSorted = [
                {
                    dataField: 'id',
                    order: 'desc'
                }
            ];
            return (
                <React.Fragment>
                    <div className='page-content'>
                        <Container fluid>
                            <Breadcrumbs title='Tableau de bord' breadcrumbItem='Votre Profil' />
                            <Row>
                                <Col lg='8'>
                                    <InfoPart user={user} />
                                </Col>
                                <Col lg='4'>
                                    <NewPassword user={user} />
                                </Col>
                            </Row>
                            <h4 className='card-title mb-4'>Vos informations de facturation</h4>
                            {this.state.successMessage && <Alert color='success'>{this.state.textMessage}</Alert>}
                            {!this.state.hideNewForm && (
                                <Card>
                                    <CardBody>
                                        <AvForm
                                            className='form-horizontal'
                                            onValidSubmit={(e, v) => {
                                                this.checkNewForm(e, v);
                                            }}
                                        >
                                            <div>
                                                <AvRadioGroup inline required name='structure' label="Type de structure (Le type 'Autres' est reservé dans le cas où votre structure ne possède pas de numéro TVA intracommunautaire)" required >
                                                    <AvRadio customInput label='Société' value='company' onChange={this.updateCurrentCompanyType}/>
                                                    <AvRadio customInput label='Personne' value='person' onChange={this.updateCurrentCompanyType}/>
                                                    <AvRadio customInput label='Autres' value='other' onChange={this.updateCurrentCompanyType}/>
                                                </AvRadioGroup>
                                            </div>
                                            <AvField
                                                name='id_user'
                                                className='form-control'
                                                value={this.state.user._id}
                                                type='text'
                                                hidden
                                            />
                                             <div className='form-group'>
                                            <AvField
                                                name='type'
                                                label='Type de structure'
                                                className='form-control'
                                                value={(this.state.currentCompanyType == "company" || this.state.currentCompanyType == "person") ? this.state.currentCompanyType : ""}
                                                placeholder='Entrez le type de votre structure'
                                                type='text'
                                                hidden={!(this.state.currentCompanyType == "other")}
                                                labelHidden={!(this.state.currentCompanyType == "other")} 
                                                />
                                            </div>
                                            {(this.state.currentCompanyType == "company" || this.state.currentCompanyType == "other") &&(         
                                            <div className='form-group'>
                                                <AvField
                                                    name='nom_societe'
                                                    label={this.state.currentCompanyType == "company" ?'Nom de société' : 'Nom de structure'}
                                                    className='form-control'
                                                    placeholder={this.state.currentCompanyType == "company" ?'Entrez le nom de votre société' : 'Entrez le nom de votre structure'}
                                                    type='text'
                                                    errorMessage='Champ invalide'
                                                    required
                                                    />
                                            </div>
                                            )}
                                            {this.state.currentCompanyType == "company" &&(                                             
                                                <div className='form-group'>
                                                    <AvField
                                                        name='num_tva_communautaire'
                                                        label='Numéro TVA intracommunautaire'
                                                        className='form-control'
                                                        placeholder='Entrez le numéro TVA intracommunautaire de votre société'
                                                        type='text'
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                                errorMessage:
                                                                    'Merci de renseigner un numéro TVA intracommunautaire valide'
                                                            },
                                                            pattern: {
                                                                value: '/^FR[0-9A-Z]{2}[0-9]{9}$/',
                                                                errorMessage:
                                                                    'Merci de renseigner un numéro TVA intracommunautaire valide'
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <div>
                                                <AvRadioGroup 
                                                    inline 
                                                    name='civilite' 
                                                    label='Civilité' 
                                                    required
                                                    hidden={this.state.currentCompanyType == ""}
                                                    labelHidden={this.state.currentCompanyType == ""}>
                                                    <AvRadio customInput label='Madame' value='madame' />
                                                    <AvRadio customInput label='Monsieur' value='monsieur' />
                                                </AvRadioGroup>
                                            </div>
                                            <div className='form-group'>
                                                <AvField
                                                    name='nom'
                                                    label='Nom'
                                                    className='form-control'
                                                    placeholder={this.state.currentCompanyType == "company" ? 'Entrez le nom de votre directeur financier': 'Entrez votre nom'}
                                                    hidden={this.state.currentCompanyType == ""}
                                                    labelHidden={this.state.currentCompanyType == ""}
                                                    type='text'
                                                    errorMessage='Champ invalide'
                                                    required
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <AvField
                                                    name='prenom'
                                                    label='Prénom'
                                                    className='form-control'
                                                    placeholder={this.state.currentCompanyType == "company" ? 'Entrez le prénom de votre directeur financier':'Entrez votre prénom' }
                                                    type='text'
                                                    errorMessage='Champ invalide'
                                                    required
                                                    hidden={this.state.currentCompanyType == ""}
                                                    labelHidden={this.state.currentCompanyType == ""}
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <AvField
                                                    name='adresse'
                                                    label='Adresse '
                                                    className='form-control'
                                                    placeholder={this.state.currentCompanyType == "company" ? 'Entrez l’adresse de votre société' : 'Entrez votre adresse'}
                                                    type='text'
                                                    errorMessage='Champ invalide'
                                                    required
                                                    hidden={this.state.currentCompanyType == ""}
                                                    labelHidden={this.state.currentCompanyType == ""}
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <AvField
                                                    name='code_postal'
                                                    label='Code Postal'
                                                    className='form-control'
                                                    placeholder={this.state.currentCompanyType == "company" ? 'Entrez le code postal de votre société' : 'Entrez votre code postal' }
                                                    type='text'
                                                    hidden={this.state.currentCompanyType == ""}
                                                    labelHidden={this.state.currentCompanyType == ""}
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: 'Merci de renseigner un code postal valide'
                                                        },
                                                        pattern: {
                                                            value: '/[0-9]{5}/',
                                                            errorMessage: 'Merci de renseigner un code postal valide'
                                                        },
                                                        minLength: {
                                                            value: 5,
                                                            errorMessage: 'Merci de renseigner un code postal valide'
                                                        },
                                                        maxLength: {
                                                            value: 5,
                                                            errorMessage: 'Merci de renseigner un code postal valide'
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <AvField
                                                    name='ville'
                                                    label='Ville'
                                                    className='form-control'
                                                    placeholder={this.state.currentCompanyType == "company" ? 'Entrez la ville de votre société' : 'Entrez votre ville'}
                                                    type='text'
                                                    errorMessage='Champ invalide'
                                                    required
                                                    hidden={this.state.currentCompanyType == ""}
                                                    labelHidden={this.state.currentCompanyType == ""}
                                                />
                                            </div>
                                            {this.state.errorMessage && (
                                                <Alert color='danger'>{this.state.textMessage}</Alert>
                                            )}
                                            <div className='text-center mt-4'>
                                                <Button 
                                                    type='submit' 
                                                    color='success'
                                                    hidden={this.state.currentCompanyType == ""}>
                                                    Enregistrer une nouvelle information de facturation
                                                </Button>{' '}
                                                <Button
                                                    color='warning'
                                                    hidden={this.state.infoFacturations == undefined || this.state.infoFacturations.length ==0}
                                                    onClick={() =>
                                                        this.setState({
                                                            hideNewForm: true,
                                                            hideEditForm: true,
                                                        })
                                                    }
                                                >
                                                    Annuler
                                                </Button>
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            )}
                            {!this.state.hideEditForm && (
                                <Card>
                                    <CardBody>
                                        <AvForm
                                            className='form-horizontal'
                                            onValidSubmit={(e, v) => {
                                                this.handleEditFormSubmit(e, v);
                                            }}
                                            model={this.state.formData}
                                        >
                                            <AvField
                                                name='id'
                                                className='form-control'
                                                value={this.state.formData._id}
                                                type='text'
                                                required
                                                hidden
                                            />
                                            <AvField
                                                name='id_user'
                                                className='form-control'
                                                value={this.state.user._id}
                                                type='text'
                                                required
                                                hidden
                                            />
                                            <div className='form-group'>
                                                <AvRadioGroup 
                                                    value={(this.state.formData._type == "person" || this.state.formData._type == "company") ? this.state.formData._type : "other" }
                                                    inline required name='structure' label="Type de structure (Le type 'Autres' est reservé dans le cas où votre structure ne possède pas de numéro TVA intracommunautaire)" required >
                                                    <AvRadio customInput label='Société' value='company' onChange={this.updateCurrentCompanyType}/>
                                                    <AvRadio customInput label='Personne' value='person' onChange={this.updateCurrentCompanyType}/>
                                                    <AvRadio customInput label='Autres' value='other' onChange={this.updateCurrentCompanyType}/>
                                                </AvRadioGroup>
                                            </div>
                                             <div className='form-group'>
                                            <AvField
                                                name='_type'
                                                label='Type de structure'
                                                className='form-control'
                                                value={(this.state.currentCompanyType == "company" || this.state.currentCompanyType == "person") ? this.state.currentCompanyType : ""}
                                                placeholder='Entrez le type de votre structure'
                                                type='text'
                                                hidden={!(this.state.currentCompanyType == "other")}
                                                labelHidden={!(this.state.currentCompanyType == "other")} 
                                                />
                                            </div>
                                            {(this.state.currentCompanyType == "company" || this.state.currentCompanyType == "other") &&(         
                                            <div className='form-group'>
                                                <AvField
                                                    name='_nom_societe'
                                                    value={this.state.formData._nom_societe}
                                                    label={this.state.currentCompanyType == "company" ?'Nom de société' : 'Nom de structure'}
                                                    className='form-control'
                                                    placeholder={this.state.currentCompanyType == "company" ?'Entrez le nom de votre société' : 'Entrez le nom de votre structure'}
                                                    type='text'
                                                    errorMessage='Champ invalide'
                                                    required
                                                    />
                                            </div>
                                            )}
                                            {this.state.currentCompanyType == "company" &&(                                             
                                                <div className='form-group'>
                                                    <AvField
                                                        name='_num_tva_communautaire'
                                                        label='Numéro TVA intracommunautaire'
                                                        className='form-control'
                                                        placeholder='Entrez le numéro TVA intracommunautaire de votre société'
                                                        type='text'
                                                        value={this.state.formData._num_tva_communautaire}
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                                errorMessage:
                                                                    'Merci de renseigner un numéro TVA intracommunautaire valide'
                                                            },
                                                            pattern: {
                                                                value: '/^FR[0-9A-Z]{2}[0-9]{9}$/',
                                                                errorMessage:
                                                                    'Merci de renseigner un numéro TVA intracommunautaire valide'
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <div>
                                                <AvRadioGroup 
                                                    inline 
                                                    name='_civilite' 
                                                    label='Civilité' 
                                                    required
                                                    value={this.state.formData._civilite}
                                                    hidden={this.state.currentCompanyType == ""}
                                                    labelHidden={this.state.currentCompanyType == ""}>
                                                    <AvRadio customInput label='Madame' value='madame' />
                                                    <AvRadio customInput label='Monsieur' value='monsieur' />
                                                </AvRadioGroup>
                                            </div>
                                            <div className='form-group'>
                                                <AvField
                                                    name='_nom'
                                                    label='Nom'
                                                    value={this.state.formData._nom}
                                                    className='form-control'
                                                    placeholder={this.state.currentCompanyType == "company" ? 'Entrez le nom de votre directeur financier': 'Entrez votre nom'}
                                                    hidden={this.state.currentCompanyType == ""}
                                                    labelHidden={this.state.currentCompanyType == ""}
                                                    type='text'
                                                    errorMessage='Champ invalide'
                                                    required
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <AvField
                                                    name='_prenom'
                                                    label='Prénom'
                                                    className='form-control'
                                                    value={this.state.formData._prenom}
                                                    placeholder={this.state.currentCompanyType == "company" ? 'Entrez le prénom de votre directeur financier':'Entrez votre prénom' }
                                                    type='text'
                                                    errorMessage='Champ invalide'
                                                    required
                                                    hidden={this.state.currentCompanyType == ""}
                                                    labelHidden={this.state.currentCompanyType == ""}
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <AvField
                                                    name='_adresse'
                                                    label='Adresse'
                                                    className='form-control'
                                                    value={this.state.formData._adresse}
                                                    placeholder={this.state.currentCompanyType == "company" ? 'Entrez l’adresse de votre société' : 'Entrez votre adresse'}
                                                    type='text'
                                                    errorMessage='Champ invalide'
                                                    required
                                                    hidden={this.state.currentCompanyType == ""}
                                                    labelHidden={this.state.currentCompanyType == ""}
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <AvField
                                                    name='_code_postal'
                                                    label='Code Postal'
                                                    className='form-control'
                                                    value={this.state.formData._code_postal}
                                                    placeholder={this.state.currentCompanyType == "company" ? 'Entrez le code postal de votre société' : 'Entrez votre code postal' }
                                                    type='text'
                                                    hidden={this.state.currentCompanyType == ""}
                                                    labelHidden={this.state.currentCompanyType == ""}
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: 'Merci de renseigner un code postal valide'
                                                        },
                                                        pattern: {
                                                            value: '/[0-9]{5}/',
                                                            errorMessage: 'Merci de renseigner un code postal valide'
                                                        },
                                                        minLength: {
                                                            value: 5,
                                                            errorMessage: 'Merci de renseigner un code postal valide'
                                                        },
                                                        maxLength: {
                                                            value: 5,
                                                            errorMessage: 'Merci de renseigner un code postal valide'
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <AvField
                                                    name='_ville'
                                                    label='Ville'
                                                    className='form-control'
                                                    value={this.state.formData._ville}
                                                    placeholder={this.state.currentCompanyType == "company" ? 'Entrez la ville de votre société' : 'Entrez votre ville'}
                                                    type='text'
                                                    errorMessage='Champ invalide'
                                                    required
                                                    hidden={this.state.currentCompanyType == ""}
                                                    labelHidden={this.state.currentCompanyType == ""}
                                                />
                                            </div>
                                            <div className='text-center mt-4'>
                                                <Button type='submit' color='info'>
                                                    Modifier cette information de facturation
                                                </Button>{' '}
                                                <Button
                                                    color='warning'
                                                    onClick={() =>
                                                        this.setState({
                                                            hideNewForm: true,
                                                            hideEditForm: true,
                                                        })
                                                    }
                                                >
                                                    Annuler
                                                </Button>
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            )}
                            {this.state.displayDeleteMessage && (
                                <Alert color='warning'>
                                    {this.state.textMessage}{' '}
                                    <Button hidden={this.state.hideDeleteButtons} className='btn btn-sm btn-info' onClick={() => this.confirmDelete()}>
                                        <b>OUI</b>
                                    </Button>
                                    <span hidden={this.state.hideDeleteButtons}> ou </span>
                                    <Button className='btn btn-sm btn-danger' onClick={() => this.cancelDelete()}>
                                        <b>{this.state.hideDeleteButtons ? 'Annuler': 'NON'}</b>
                                    </Button>
                                </Alert>
                            )}
                            {this.state.infoFacturations && (
                                <Card>
                                    <CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField='id'
                                            columns={this.state.usersListColumns}
                                            data={infoFacturations}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField='id'
                                                    columns={this.state.IFListColumns}
                                                    data={infoFacturations}
                                                    search
                                                >
                                                    {toolkitprops => (
                                                        <React.Fragment>
                                                            <Row className='mb-2'>
                                                                <Col sm='2'>
                                                                    <div className='search-box ms-2 mb-2 d-inline-block'>
                                                                        <div className='position-relative'>
                                                                            <SearchBar
                                                                                placeholder='Rechercher...'
                                                                                {...toolkitprops.searchProps}
                                                                            />
                                                                            <i className='bx bx-search-alt search-icon' />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col sm='4 offset-6'>
                                                                    <Button
                                                                        color='primary'
                                                                        onClick={() => this.setState({ hideNewForm: false, hideEditForm: true})}
                                                                    >
                                                                        Renseigner une nouvelle information de facturation
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl='12'>
                                                                    <div className='table-responsive'>
                                                                        <BootstrapTable
                                                                            {...toolkitprops.baseProps}
                                                                            {...paginationTableProps}
                                                                            defaultSorted={defaultSorted}
                                                                            classes={
                                                                                'table align-middle table-nowrap table-hover'
                                                                            }
                                                                            bordered={false}
                                                                            striped={false}
                                                                            responsive
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className='align-items-md-center mt-30'>
                                                                <Col className='pagination pagination-rounded justify-content-end mb-2'>
                                                                    <PaginationListStandalone {...paginationProps} />
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                            )}
                        </Container>
                    </div>
                </React.Fragment>
            );
        }
    }
}

export default withNamespaces()(profile);
