import React, { Component } from 'react';

import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { axiosInstance } from '../../../helpers/axios';
import env from '../../../env';
class NewPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user,
            email_send: false,
            is_password_same: null
        };
        this.handleInitiateNewPassword = this.handleInitiateNewPassword.bind(this)
    }

    handleInitiateNewPassword() {
        this.setState({ email_send: false });
        axiosInstance
            .get(env.REACT_APP_API_URL + '/user/' + this.state.user._email + '/change')
            .then(res => {
                this.setState({ email_send: res.data });
            })
            .catch(err => {
                this.setState({ errorMessage: err.message });
                this.setState({ email_send: false });
            });
    }

    render() {
        const { user } = this.state;
        return (
            <React.Fragment>
                <Card className='overflow-hidden'>
                    <div className='bg-soft-primary'>
                        <Row>
                            <Col xs='7'>
                                <div className='text-primary p-3'>
                                    <h5 className='text-primary'>Mot de passe</h5>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <CardBody className='pt-3'>
                        <Row>
                            {this.state.email_send === false
                                ? (<Col sm='12'>
                                    <Button onClick={() => { this.handleInitiateNewPassword() }} color='primary'>
                                        Changer de mot de passe
                                    </Button>
                                </Col>)
                                : (<Col sm='12'>
                                    <div className='text-primary'>
                                        <h5 className='text-primary'>Email envoyé, suivez la procédure pour changer de mot de passe.</h5>
                                    </div>
                                </Col>)
                            }

                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default NewPassword;
