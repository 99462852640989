import React, { Component } from 'react';
import { Card, CardText, CardBody, CardTitle, Row, Col } from 'reactstrap';
import { axiosInstance } from '../../helpers/axios';
import env from '../../env';

//Import Breadcrumb
import Breadcrumbs from '../../components/ThemeCMBP/Partials/Breadcrumb';

import { Link } from 'react-router-dom';

//i18n
import { withNamespaces } from 'react-i18next';

class Dashboard extends Component {
    state = {};

    componentDidMount() {
        axiosInstance.get(env.REACT_APP_API_URL + '/user/me');
    }

    render() {
        return (
            <React.Fragment>
                <div className='page-content'>
                    <div className='container-fluid'>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title='Accueil' breadcrumbItem={this.props.t('homepage')} />
                        <Row>
                            <Card>
                                <CardBody>
                                    <CardTitle className='h5'>Bienvenue sur CheckMyBP</CardTitle>
                                    <CardText>
                                        <ul>
                                            <li>
                                                Au préalable de l'utilisation de CheckMyBP, vous devez recueillir le
                                                maximum d'éléments concernant votre business plan : le compte de
                                                résultat, bilan , trésorerie... Toutefois, si vous ne disposez dans un
                                                premier temps que du compte de résultat, vous pouvez déjà le valider
                                                avec le module correspondant. Vous pourrez alors compléter vos
                                                prévisions au fur et à mesure, car vous disposez d'une période
                                                d'utilisation de 6 mois.
                                            </li>
                                            <li>
                                                Pour chaque information renseignée, une aide contextuelle vous permet de
                                                la préciser au mieux. L'aide peut contenir des alertes sur les enjeux
                                                associés, pour vous guider le plus possible dans la démarche d'analyse
                                                de votre projet.
                                            </li>
                                            <li>
                                                Vous pourrez alors corriger au mieux, à votre rythme, vos prévisions.
                                                Une seconde aide de cohérence vous indiquera quelques pistes
                                                d'amélioration.
                                            </li>
                                        </ul>
                                        <p>Nous vous préconisons l'approche suivante :</p>
                                        <ul>
                                            <li>
                                                Un premier survol des différents modules vous permet d'avoir une vision
                                                à 360° de la cohérence globale visée et des thèmes abordés.
                                            </li>
                                            <li>
                                                Ensuite, il est plus simple de valider progressivement d'abord le P&L et
                                                les charges de personnel. Dans un second temps, les modules de
                                                financement par prêt ou levée de fonds. Vous ajusterez alors la
                                                trésorerie.
                                            </li>
                                            <li>
                                                Le cas échéant, il vous sera peut-être utile de modifier de nouveau
                                                quelques éléments de votre P&L (par ex augmenter le CA ou diminuer les
                                                charges), afin d'obtenir un maximum de voyants verts.
                                            </li>
                                            <li>
                                                Si vous n'avez pas la totalité des voyants verts, votre projet peut
                                                néanmoins être "globalement cohérent". L'essentiel est d'être alerté sur
                                                les points de vigilance.
                                            </li>
                                        </ul>
                                        <p>À vous de jouer maintenant !</p>
                                        <Row>
                                            <Col md="2">
                                                <Link
                                                    className='btn btn-primary'
                                                    to={{
                                                        pathname: '/business-plan/list'
                                                    }}
                                                >
                                                    Vos business plans
                                                </Link>
                                            </Col>
                                            <Col md="3">
                                                <Link
                                                    className='btn btn-primary'
                                                    to={{
                                                        pathname: '/business-plan/new'
                                                    }}
                                                >
                                                    Créer votre business plan
                                                </Link>
                                            </Col>
                                        </Row>

                                    </CardText>
                                </CardBody>
                            </Card>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(Dashboard);
