import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Modal, Button, ModalHeader, ModalBody } from 'reactstrap';
import { axiosInstance } from '../../helpers/axios';

import { Redirect, Link } from 'react-router-dom';
import moment from 'moment';
import I18next from 'i18next';
import env from '../../env';

//Import Breadcrumb
import Breadcrumbs from '../../components/ThemeCMBP/Partials/Breadcrumb';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

//i18n
import { withNamespaces } from 'react-i18next';

import '../../assets/scss/datatable.scss';

const currentLanguage = I18next.language;

class indexBusinessPlan extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            user: null,
            bp: null,
            selectedBp: [],
            isLoading: true,
            message: null,
            modal: false,
            dropdownOpen: false,
            refresh: false,
            bpListColumns: [
                {
                    dataField: '_name',
                    text: 'Nom',
                    sort: true,
                    formatter: (value, row) => <b>{value}</b>
                },
                {
                    dataField: '_user._login',
                    text: 'Créé par',
                    sort: true
                },
                {
                    dataField: '_modification_date',
                    text: 'Modifié le',
                    sort: true,
                    formatter: (value, row) =>
                        new Intl.DateTimeFormat(currentLanguage, {
                            year: 'numeric',
                            month: 'long',
                            day: '2-digit'
                        }).format(moment(value).toDate())
                },
                {
                    dataField: '_key._date_activation',
                    text: 'Créé le',
                    sort: true,
                    formatter: (value, row) =>
                        new Intl.DateTimeFormat(currentLanguage, {
                            year: 'numeric',
                            month: 'long',
                            day: '2-digit'
                        }).format(moment(value).toDate())
                },
                {
                    dataField: '_key._date_expiration',
                    text: 'Expire le',
                    sort: true,
                    formatter: (value, row) =>
                        new Intl.DateTimeFormat(currentLanguage, {
                            year: 'numeric',
                            month: 'long',
                            day: '2-digit'
                        }).format(moment(value).toDate())
                },
                {
                    dataField: '_status',
                    text: 'Statut',
                    sort: true,
                    formatter: (value, row) =>
                        value._name === 'init' ? (
                            <Link to='#' className='badge badge-soft-primary font-size-11 m-1' key='#'>
                                Initialisé
                            </Link>
                        ) : value._name === 'invalid' ? (
                            <Link to='#' className='badge badge-soft-danger font-size-11 m-1' key='#'>
                                Invalide
                            </Link>
                        ) : value._name === 'missing data' ? (
                            <Link to='#' className='badge badge-soft-warning font-size-11 m-1' key='#'>
                                Manque d'information
                            </Link>
                        ) : value._name === 'valid' ? (
                            <Link to='#' className='badge badge-soft-success font-size-11 m-1' key='#'>
                                Valide
                            </Link>
                        ) : value._name === 'warning' ? (
                            <Link to='#' className='badge badge-soft-primary font-size-11 m-1' key='#'>
                                A compléter
                            </Link>
                        ) : value._name === 'working' ? (
                            <Link to='#' className='badge badge-soft-success font-size-11 m-1' key='#'>
                                Fonctionnel
                            </Link>
                        ) : (
                            <Link to='#' className='badge badge-soft-danger font-size-11 m-1' key='#'>
                                Erreur
                            </Link>
                        )
                },
                {
                    dataField: 'menu',
                    text: 'Action',
                    formatter: (cellContent, bp) => (
                        !bp._is_archived ? (
                            <div className='d-flex gap-3'>
                                <div className='d-flex'>
                                    <Link
                                        className='text-success p-1'
                                        to={{
                                            pathname: `/business-plan/resume/${bp._id}`,
                                            state: { user: this.state.user, id_bp: bp._id }
                                        }}
                                    >
                                        {' '}
                                        <i className='mdi  mdi-eye font-size-18' id='eyetooltip'></i>
                                    </Link>
                                    <Link
                                        className='text-success p-1'
                                        to={{
                                            pathname: `/business-plan/view/${bp._uuid_bp}`,
                                            state: { uuid_bp: bp._uuid_bp }
                                        }}
                                    >
                                        {' '}
                                        <i className='mdi mdi-pencil font-size-18' id='edittooltip'></i>
                                    </Link>
                                    {/* <Link className='text-warning p-1' to='#'>
                                        <i
                                            className='mdi mdi-archive font-size-18'
                                            id='archivetooltip'
                                            onClick={() => this.handleArchiveBP(bp)}
                                        ></i>
                                    </Link> */}
                                </div>
                            </div>
                        ) : (
                            <div className='d-flex gap-3'>
                                <div className='d-flex'>
                                    <Link
                                        className='text-success p-1'
                                        to={{
                                            pathname: `/business-plan/archive/${bp._id}`,
                                            state: { user: this.state.user, id_bp: bp._id }
                                        }}
                                    >
                                        {' '}
                                        <i className='mdi  mdi-eye font-size-18' id='eyetooltip'></i>
                                    </Link>
                                </div>
                            </div >
                        )
                        /* <Link className='text-danger p-1' to='#'>
                            <i
                                className='mdi mdi-delete font-size-18'
                                id='deletetooltip'
                                onClick={() => this.handleDeleteBP(bp)}
                            ></i>
                        </Link>
                        
                        <Link className='text-info p-1' to='#'>
                            <i
                                className='mdi mdi-file-export-outline font-size-18'
                                id='deletetooltip'
                                onClick={() => this.handleExportBP(bp)}
                            ></i>
                        </Link> */
                    )
                }
            ]
        };
        this.handleDeleteBP = this.handleDeleteBP.bind(this);
        this.handleArchiveBP = this.handleArchiveBP.bind(this);
        this.handleExportBP = this.handleExportBP.bind(this);
        this.deleteBP = this.deleteBP.bind(this);
        this.archiveBP = this.archiveBP.bind(this);
        this.exportBP = this.exportBP.bind(this);
    }

    componentDidMount() {
        this.setState({ message: this.props.location?.state?.message });
        axiosInstance
            .get(env.REACT_APP_API_URL + '/user/me')
            .then(res => {
                this.setState({ user: res.data });
            })
            .then(res => {
                axiosInstance
                    .get(env.REACT_APP_API_URL + '/business-plan/user/' + this.state.user._id)
                    .then(res => {
                        this.setState({ bp: res.data, isLoading: false });
                    })
                    .catch(err => {
                        this.setState({ errorMessage: err.message });
                    });
            })
            .catch(err => {
                this.setState({ errorMessage: err.message });
            });
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    handleDeleteBP = arg => {
        const bp = arg;

        this.setState({
            selectedBp: {
                id: bp._uuid_bp
            },
            isDelete: true,
            isArchive: false,
            isExport: false
        });

        this.toggle();
    };

    handleArchiveBP = arg => {
        const bp = arg;
        this.setState({
            selectedBp: {
                id: bp._id,
                is_archived: bp._is_archived
            },
            isDelete: false,
            isArchive: true,
            isExport: false
        });

        this.toggle();
    };

    handleExportBP = arg => {
        const bp = arg;

        this.setState({
            selectedBp: {
                id: bp._uuid_bp,
            },
            isDelete: false,
            isArchive: false,
            isExport: true
        });

        this.toggle();
    };

    deleteBP() {
        console.log('delete =>');
        console.log(this.state.selectedBp.id);
        this.toggle();
    }

    archiveBP() {
        axiosInstance
            .put(env.REACT_APP_API_URL + '/business-plan/archive/' + this.state.selectedBp.id)
            .then(res => {
                console.log("Bp correctement archivé");
                this.setState({ refresh: true });
            })
            .catch(err => {
                console.error("Erreur lors de l'archivage du bp");
                this.setState({ errorMessage: err.message });
            });
        this.toggle();
    }

    exportBP() {
        console.log('export =>');
        console.log(this.state.selectedBp.id);
        this.toggle();
    }

    render() {
        if (this.state.refresh) {
            axiosInstance
                .get(env.REACT_APP_API_URL + '/user/me')
                .then(res => {
                    this.setState({ user: res.data });
                })
                .then(res => {
                    axiosInstance
                        .get(env.REACT_APP_API_URL + '/business-plan/user/' + this.state.user._id)
                        .then(res => {
                            this.setState({ bp: res.data, isLoading: false });
                        })
                        .catch(err => {
                            this.setState({ errorMessage: err.message });
                        });
                })
                .catch(err => {
                    this.setState({ errorMessage: err.message });
                });
        }
        if (this.state.isLoading) {
            return <p>Loading ...</p>;
        } else {
            const { bp } = this.state;
            const { SearchBar } = Search;
            const { isDelete, isArchive } = this.state;

            const pageOptions = {
                sizePerPage: 10,
                totalSize: bp.length,
                custom: true
            };


            const defaultSorted = [
                {
                    dataField: 'id',
                    order: 'desc'
                }
            ];
            return (
                <React.Fragment>
                    <div className='page-content'>
                        <Container fluid>
                            {/* Render Breadcrumb */}
                            <Breadcrumbs
                                title={this.props.t('dashboard')}
                                breadcrumbItem={this.props.t('liste des business plan')}
                            />
                            <Row>
                                <Col className='col-12'>
                                    <Card>
                                        <CardBody>
                                            <PaginationProvider
                                                pagination={paginationFactory(pageOptions)}
                                                keyField='id'
                                                columns={this.state.bpListColumns}
                                                data={bp}
                                            >
                                                {({ paginationProps, paginationTableProps }) => (
                                                    <ToolkitProvider
                                                        keyField='id'
                                                        columns={this.state.bpListColumns}
                                                        data={bp}
                                                        search
                                                    >
                                                        {toolkitprops => (
                                                            <React.Fragment>
                                                                <Row className='mb-2'>
                                                                    <Col sm='4'>
                                                                        <div className='search-box ms-2 mb-2 d-inline-block'>
                                                                            <div className='position-relative'>
                                                                                <SearchBar
                                                                                    placeholder='Rechercher...'
                                                                                    {...toolkitprops.searchProps}
                                                                                />
                                                                                <i className='bx bx-search-alt search-icon' />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xl='12'>
                                                                        <div className='table-responsive'>
                                                                            <BootstrapTable
                                                                                {...toolkitprops.baseProps}
                                                                                {...paginationTableProps}
                                                                                defaultSorted={defaultSorted}
                                                                                classes={
                                                                                    'table align-middle table-nowrap table-hover'
                                                                                }
                                                                                bordered={false}
                                                                                striped={false}
                                                                                responsive
                                                                            />

                                                                            <Modal
                                                                                isOpen={this.state.modal}
                                                                                className={this.props.className}
                                                                            >
                                                                                <ModalHeader
                                                                                    toggle={this.toggle}
                                                                                    tag='h4'
                                                                                >
                                                                                    {isDelete === true
                                                                                        ? 'Supprimer '
                                                                                        : isArchive === true
                                                                                            ? 'Archiver '
                                                                                            : 'Exporter '}
                                                                                    le business plan
                                                                                </ModalHeader>
                                                                                <ModalBody>
                                                                                    {isDelete === true ? (
                                                                                        <p>
                                                                                            Attention, la suppression
                                                                                            est définitive.
                                                                                        </p>
                                                                                    ) : isArchive === true ? (
                                                                                        <p>
                                                                                            Attention, l'archivage est
                                                                                            définitif.
                                                                                        </p>
                                                                                    ) : (
                                                                                        <p>
                                                                                            Voulez-vous exporter votre
                                                                                            business plan en PDF ?
                                                                                        </p>
                                                                                    )}
                                                                                    <Button
                                                                                        color='secondary'
                                                                                        onClick={this.toggle}
                                                                                    >
                                                                                        Annuler
                                                                                    </Button>{' '}
                                                                                    {isDelete === true ? (
                                                                                        <Button
                                                                                            color='danger'
                                                                                            onClick={() =>
                                                                                                this.deleteBP(
                                                                                                    bp.uuid_bp
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            Supprimer
                                                                                        </Button>
                                                                                    ) : isArchive === true ? (
                                                                                        <Button
                                                                                            color='warning'
                                                                                            onClick={() =>
                                                                                                this.archiveBP(
                                                                                                    bp._id
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            Archiver
                                                                                        </Button>
                                                                                    ) : (
                                                                                        <Button
                                                                                            color='info'
                                                                                            onClick={() =>
                                                                                                this.exportBP(
                                                                                                    bp.uuid_bp
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            Exporter
                                                                                        </Button>
                                                                                    )}
                                                                                </ModalBody>
                                                                            </Modal>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row className='align-items-md-center mt-30'>
                                                                    <Col className='pagination pagination-rounded justify-content-end mb-2'>
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolkitProvider>
                                                )}
                                            </PaginationProvider>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }
    }
}

export default withNamespaces()(indexBusinessPlan);
