import React, { Component } from 'react';

import { Alert, Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import env from '../../env';

// Redux
import { connect } from 'react-redux';
import { axiosInstance } from '../../helpers/axios';
import { Redirect } from 'react-router-dom';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

//i18n
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import I18next from 'i18next';
const currentLanguage = I18next.language;
class InitFactureVirement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: null,
            users: null,
            billings: null,
            ChoosenBilling: null,
            isLoading: true,
            notAuthorized: null,
            usersListColumns: [
                {
                    dataField: '_email',
                    text: 'Email',
                    sort: true,
                    formatter: (value, row) => <p>{value}</p>
                },
                {
                    dataField: '_login',
                    text: 'Identifiant',
                    sort: true,
                    formatter: (value, row) => <p>{value}</p>
                },
                {
                    dataField: '_phone',
                    text: 'N° de téléphone',
                    sort: false,
                    formatter: (value, row) => <p>{value}</p>
                },
                {
                    dataField: 'menu',
                    text: 'Action',
                    formatter: (cellContent, user) => (
                        <div className='d-flex gap-3'>
                            <div className='d-flex'>
                                <Button className='btn btn-info ml-5' onClick={() => this.handleChoosenUser(user)}>
                                    Choisir cet utilisateur
                                </Button>
                            </div>
                        </div>
                    )
                }
            ],
            billingListColumns: [
                {
                    dataField: '_num_facture',
                    text: 'Numéro de facture',
                    sort: true,
                    formatter: (value, row) => <b>{value}</b>
                },
                {
                    dataField: '_date_creation',
                    text: "Date d'édition",
                    sort: true,
                    formatter: (value, row) =>
                        new Intl.DateTimeFormat(currentLanguage, {
                            year: 'numeric',
                            month: 'long',
                            day: '2-digit'
                        }).format(moment(value).toDate())
                },
                {
                    dataField: 'menu',
                    text: 'A payer',
                    formatter: (cellContent, billing) =>
                        billing._id_type_facture !== 1 ? (
                            <Button
                                href={'data:application/octet-stream;base64,' + billing._pdf}
                                className='btn btn-sm btn-success btn-block'
                                download={billing._num_facture + '.pdf'}
                            >
                                Télécharger
                            </Button>
                        ) : (
                            <p className='text-center'>-</p>
                        )
                },
                {
                    dataField: 'menu',
                    text: 'Acquitée',
                    formatter: (cellContent, billing) =>
                        billing._id_type_facture !== 1 ? (
                            billing._statut_paiement === 2 ? (
                                <Button
                                    href={'data:application/octet-stream;base64,' + billing._pdfA}
                                    className='btn btn-sm btn-success btn-block'
                                    download={billing._num_facture + '.pdf'}
                                >
                                    Télécharger
                                </Button>
                            ) : (
                                <Button
                                    className='btn btn-sm btn-info btn-block'
                                    onClick={() => this.handleChoosenBilling(billing)}
                                >
                                    Confirmer le paiement
                                </Button>
                            )
                        ) : (
                            <Button
                                href={'data:application/octet-stream;base64,' + billing._pdf}
                                className='btn btn-sm btn-success btn-block'
                                download={billing._num_facture + '.pdf'}
                            >
                                Télécharger
                            </Button>
                        )
                }
            ]
        };

        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    resetMessage() {
        this.setState({ message: null });
    }

    componentDidMount() {
        this.resetMessage();
        if (localStorage.getItem('authUser')) {
            const obj = JSON.parse(localStorage.getItem('authUser'));
            if (obj.user_info != undefined) {
                var droits = obj.user_info.droits_user;
                console.log(droits);
                if (droits.length != 0) {
                    if (droits.find(x => x._id_droit == 1) != undefined) {
                        axiosInstance
                            .get(env.REACT_APP_API_URL + '/user/all')
                            .then(res => {
                                this.setState({ users: res.data, notAuthorized: false, isLoading: false });
                            })
                            .catch(err => {
                                this.setState({ notAuthorized: true });
                                console.log(err);
                            });
                    }
                    else
                        this.setState({ notAuthorized: true });
                }
            }
        }
    }

    handleChoosenUser(values) {
        this.resetMessage();
        axiosInstance
            .get(env.REACT_APP_API_URL + '/billing/user/' + values._id)
            .then(res => {
                this.setState({ billings: res.data });
            })
            .catch(err => {
                this.setState({ notAuthorized: true });
                console.log(err);
            });
    }

    handleChoosenBilling(values) {
        this.resetMessage();
        this.setState({ ChoosenBilling: values });
    }

    // handleValidSubmit
    handleValidSubmit() {
        this.resetMessage();
        axiosInstance
            .post(env.REACT_APP_API_URL + '/payment/confirm/virement', {
                id_pay: this.state.ChoosenBilling._id_paiement,
                id_facture: this.state.ChoosenBilling._id
            })
            .then(res => {
                if (res.status === 201) {
                    this.setState({
                        billings: null,
                        ChoosenBilling: null,
                        message: 'Le paiement a été validé et une facture de confirmation de paiement a été édité'
                    });
                }
            })
            .catch(err => {
                this.setState({ notAuthorized: true });
                console.log(err);
            });
    }

    render() {
        if (this.state.notAuthorized) {
            return (
                <Redirect
                    to={{
                        pathname: '/logout'
                    }}
                />
            );
        } else if (this.state.isLoading) {
            return <p>Loading ...</p>;
        } else {
            const { users, billings } = this.state;
            const { SearchBar } = Search;

            const pageOptions = {
                sizePerPage: 10,
                totalSize: users.length,
                custom: true
            };

            const defaultSorted = [
                {
                    dataField: 'id',
                    order: 'desc'
                }
            ];
            return (
                <React.Fragment>
                    <div className='page-content'>
                        <Container fluid>
                            <Row>
                                <Col className='col-12'>
                                    {this.state.message ? <Alert color='success'>{this.state.message}</Alert> : null}
                                    {(this.state.ChoosenBilling !== null || this.state.billings !== null) && (
                                        <Button
                                            className='btn btn-primary mb-4'
                                            onClick={() => this.setState({ billings: null, ChoosenBilling: null })}
                                        >
                                            {' '}
                                            Retour à la liste des utilisateurs
                                        </Button>
                                    )}

                                    {this.state.users &&
                                        this.state.billings === null &&
                                        this.state.ChoosenBilling === null && (
                                            <Card>
                                                <CardBody>
                                                    <h3>Liste des utilisateurs</h3>
                                                    <br />
                                                    <PaginationProvider
                                                        pagination={paginationFactory(pageOptions)}
                                                        keyField='id'
                                                        columns={this.state.usersListColumns}
                                                        data={users}
                                                    >
                                                        {({ paginationProps, paginationTableProps }) => (
                                                            <ToolkitProvider
                                                                keyField='id'
                                                                columns={this.state.usersListColumns}
                                                                data={users}
                                                                search
                                                            >
                                                                {toolkitprops => (
                                                                    <React.Fragment>
                                                                        <Row className='mb-2'>
                                                                            <Col sm='4'>
                                                                                <div className='search-box ms-2 mb-2 d-inline-block'>
                                                                                    <div className='position-relative'>
                                                                                        <SearchBar
                                                                                            placeholder='Rechercher...'
                                                                                            {...toolkitprops.searchProps}
                                                                                        />
                                                                                        <i className='bx bx-search-alt search-icon' />
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col xl='12'>
                                                                                <div className='table-responsive'>
                                                                                    <BootstrapTable
                                                                                        {...toolkitprops.baseProps}
                                                                                        {...paginationTableProps}
                                                                                        defaultSorted={defaultSorted}
                                                                                        classes={
                                                                                            'table align-middle table-nowrap table-hover'
                                                                                        }
                                                                                        bordered={false}
                                                                                        striped={false}
                                                                                        responsive
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className='align-items-md-center mt-30'>
                                                                            <Col className='pagination pagination-rounded justify-content-end mb-2'>
                                                                                <PaginationListStandalone
                                                                                    {...paginationProps}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                                )}
                                                            </ToolkitProvider>
                                                        )}
                                                    </PaginationProvider>
                                                </CardBody>
                                            </Card>
                                        )}
                                    {this.state.users && this.state.billings && this.state.ChoosenBilling === null && (
                                        <Card>
                                            <CardBody>
                                                <h3>Liste des factures</h3>
                                                <br />
                                                <PaginationProvider
                                                    pagination={paginationFactory(pageOptions)}
                                                    keyField='id'
                                                    columns={this.state.IFListColumns}
                                                    data={billings}
                                                >
                                                    {({ paginationProps, paginationTableProps }) => (
                                                        <ToolkitProvider
                                                            keyField='id'
                                                            columns={this.state.billingListColumns}
                                                            data={billings}
                                                            search
                                                        >
                                                            {toolkitprops => (
                                                                <React.Fragment>
                                                                    <Row className='mb-2'>
                                                                        <Col sm='4'>
                                                                            <div className='search-box ms-2 mb-2 d-inline-block'>
                                                                                <div className='position-relative'>
                                                                                    <SearchBar
                                                                                        placeholder='Rechercher...'
                                                                                        {...toolkitprops.searchProps}
                                                                                    />
                                                                                    <i className='bx bx-search-alt search-icon' />
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xl='12'>
                                                                            <div className='table-responsive'>
                                                                                <BootstrapTable
                                                                                    {...toolkitprops.baseProps}
                                                                                    {...paginationTableProps}
                                                                                    defaultSorted={defaultSorted}
                                                                                    classes={
                                                                                        'table align-middle table-nowrap table-hover'
                                                                                    }
                                                                                    bordered={false}
                                                                                    striped={false}
                                                                                    responsive
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className='align-items-md-center mt-30'>
                                                                        <Col className='pagination pagination-rounded justify-content-end mb-2'>
                                                                            <PaginationListStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </React.Fragment>
                                                            )}
                                                        </ToolkitProvider>
                                                    )}
                                                </PaginationProvider>
                                            </CardBody>
                                        </Card>
                                    )}
                                    {this.state.users && this.state.billings && this.state.ChoosenBilling && (
                                        <Card>
                                            <CardBody>
                                                {this.state.ChoosenBilling && (
                                                    <>
                                                        <b>{this.state.ChoosenBilling._nom_societe}</b>
                                                        <br />
                                                        <br />
                                                        <AvForm
                                                            className='form-horizontal'
                                                            onValidSubmit={this.handleValidSubmit}
                                                        >
                                                            <div className='mt-3'>
                                                                <button
                                                                    className='btn btn-primary btn-block waves-effect waves-light'
                                                                    type='submit'
                                                                >
                                                                    Confirmer la validation du paiement pour la facture
                                                                    N° {this.state.ChoosenBilling._num_facture}
                                                                </button>
                                                                <br />
                                                                <br />
                                                                <br />
                                                            </div>
                                                        </AvForm>
                                                        <br />
                                                    </>
                                                )}
                                            </CardBody>
                                        </Card>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }
    }
}

const mapStatetoProps = () => { };

export default connect(mapStatetoProps)(withNamespaces()(InitFactureVirement));
