import { axiosInstance } from '../axios';
import axios from 'axios';
import env from '../../env';

// Get All Users
const getUsers = (url, data) => {
    return axiosInstance
        .get(env.REACT_APP_API_URL + url, data)
        .then(response => {
            if (response.status === 400 || response.status === 500) throw response.data;
            return response.data;
        })
        .catch(err => {
            throw err[1];
        });
};

// Register
const postRegister = (url, data) => {
    return axios
        .post(env.REACT_APP_API_URL + url, data)
        .then(response => {
            if (response.status === 200 || response.status === 201) {
                return response.data;
            } else {
                throw response.status;
            }
        })
        .catch(error => {
            var message;
            switch (error) {
                case 403:
                    message = 'Cet utilisateur est déjà enregistré. Merci de vous connecter.';
                    break;
                case 500:
                    message = 'Sorry! something went wrong, please contact our support team';
                    break;
                default:
                    message = error[1];
                    break;
            }
            throw message;
        });
};

export { getUsers, postRegister };
