import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from '../../components/ThemeCMBP/Partials/Breadcrumb';

//i18n
import { withNamespaces } from 'react-i18next';

class CGU extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    togglemodal = () => {
        this.setState(prevState => ({}));
    };

    render() {
        return (
            <React.Fragment>
                <div className='page-content'>
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs title={this.props.t('dashboard')} breadcrumbItem={this.props.t('cgu')} />

                        <Row>
                            <Col lg='12'>
                                <Card>
                                    <CardBody>
                                        <h1>CONDITIONS GENERALES D’UTILISATION ET DE VENTE(CGU-CGV)</h1>
                                        <p>Les présentes conditions générales de vente incluent les conditions générales d’utilisation (ces termes et conditions étant collectivement désignées ci-après les « CGV ») du site internet CHECKMYBP accessible à l’adresse suivante : https://checkmybp.fr/cgu-cgv/ Les CGV sont conclues entre la Société CHECKMYBP (ci-après désignée « CHECKMYBP ») société par actions simplifiée enregistrée au RCS de Nanterre sous le numéro 889 979 787 dont le siège social est situé 63 Bis rue de l’Yser 92330 SCEAUX, et tout utilisateur du site internet et des services fournis via ce dernier (ci-après désigné le « Client »),</p>
                                        <p>Ci-après désignés individuellement la <strong>Partie</strong> et collectivement les <strong>Parties</strong>.</p>
                                        <p>Les CGV s’appliquent à toute prestation de service fournie par CHECKMYBP au Client.</p>
                                        <p>Avant toute souscription aux services fournis par CHECKMYBP et activation de son compte client, le Client s’engage à lire, accepter et respecter les présentes CGV. </p>
                                        <p>CHECKMYBP met à disposition du Client les présentes CGV depuis la page de son site internet accessible à l’adresse suivante : https://checkmybp.fr/cgu-cgv/. </p>
                                        <p>Le Client peut, à tout moment, demander expressément les présentes CGV à CHECKMYBP qui les lui communiquera par le biais de l’envoi du lien URL adéquat.</p>
                                        <br />
                                        <h2>ARTICLE 1 – DEFINITIONS </h2>
                                        <p><strong>CGV</strong> désigne les conditions générales de vente qui incluent conditions générales d’utilisation.</p>
                                        <p><strong>Client</strong> désigne toute personne physique ou morale souscrivant un abonnement aux services fournis par CHECKMYBP, dans le cadre de son activité professionnelle actuelle ou à venir. </p>
                                        <p><strong>Progiciels</strong> désigne les services fournis par CHECKMYBP, soit les logiciels liés aux modules :</p>
                                        <p>&nbsp;&nbsp;&nbsp;•&nbsp;Compte de résultat/ P&L,<br/>&nbsp;&nbsp;&nbsp;•&nbsp;Bilan,<br/>&nbsp;&nbsp;&nbsp;•&nbsp;Levée de fonds,<br/>&nbsp;&nbsp;&nbsp;•&nbsp;Emprunt bancaire,<br/>&nbsp;&nbsp;&nbsp;•&nbsp;BFR et trésorerie,<br/>&nbsp;&nbsp;&nbsp;•&nbsp;Charges de personnel,</p>
                                        <p><strong>Service</strong> désigne l’accès aux Progiciels fourni au Client par CHECKMYBP en contrepartie du règlement du prix prévu à l’Article 6.</p>
                                        <p><strong>Site Internet</strong> désigne le site internet sur lequel le Client a accès aux Progiciels, accessible à l’adresse suivante : https://checkmybp.fr/ </p>
                                        <p><strong>Compte Client</strong> désigne le compte du Client lui permettant d’accéder au Service et aux Progiciels sur le Site Internet. </p>
                                        <br/>
                                        <h2>ARTICLE 2 – OBJET </h2>
                                        <p>
                                        Les présentes CGV ont pour objet de définir les droits et obligations des Parties dans le cadre de la réalisation du Service.<br/>
                                        Sauf dérogation formelle et expresse de CHECKMYBP, les CGV prévalent sur tout autre document communiqué au Client.
                                        </p>
                                        <p>Dans le cas où l’une quelconque des dispositions des présentes CGV serait déclarée nulle ou non écrite, les autres dispositions resteront intégralement en vigueur et seront interprétées de façon à respecter l’intention originelle des Parties.</p>
                                        <p>Concomitamment à toute souscription au Service, le Client déclare :<br/>
                                        &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;avoir pris connaissance des présentes CGV,<br/>
                                        &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;les accepter expressément et,<br/>
                                        &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;se porter fort de leur respect directement et/ou par ses salariés le cas échéant.
                                        </p>
                                        <p>L’acceptation des CGV ne peut être que pleine et entière. Toute création d’un Compte Client avec réserve est considérée comme nulle et non avenue. Si le Client n’accepte pas d’être lié par les CGV, le Client ne peut accéder au Service.</p>
                                        <br />
                                        <h2>ARTICLE 3 – CONDITIONS D’UTILISATION </h2>
                                        <p>Les Progiciels, permettent à un porteur de projet, un entrepreneur ou plus généralement à toute personne (le Client) :  <br/><br/>
                                        &nbsp;&nbsp;&nbsp;•&nbsp;de disposer d’informations contextuelles sur les données financières de base abordées par les Progiciels,<br/>
                                        &nbsp;&nbsp;&nbsp;•&nbsp;d’obtenir une pré-validation de la cohérence des prévisions financières établies par le Client, selon les principes d’analyse retenus par CHECHMYBP.
                                        </p>
                                        <p>CHECKMYBP a pour objet d’accompagner les porteurs de projet à venir ou dirigeants de sociétés existantes à valider la cohérence des prévisions financières de leur <i>business plan</i>. </p>
                                        <p>Le Service proposé par CHECKMYBP est constitué de Progiciels qui sont des outils pédagogiques visant à démocratiser des notions de données financières requises aux fins de constitution d’un <i>business plan.</i></p>
                                        <p>Le Client s’engage à être l’unique utilisateur des Progiciels. Le Client s’interdit d’octroyer des sous-licences des Progiciels sans l’accord écrit préalable de CHECKMYBP.</p>
                                        <p>Le Client ne pourra faire bénéficier à un tiers de l’accès aux Progiciels, à quelque titre et sous quelque forme que ce soit.</p>
                                        <p>Les Progiciels mis à disposition ne peuvent être utilisés qu’à partir du Site Internet hébergeant les Progiciels de CheckmyBP. </p>
                                        <p>A cet effet, le Client est informé et accepte qu’une connexion Internet effective est une condition essentielle à la réalisation du Service par CHECKMYBP et qu’elle est exclusivement à la charge du Client.</p>
                                        <p>Les Progiciels mis à disposition du Client par CHECKMYBP demeurent la propriété entière et exclusive de CHECKMYBP, ainsi que toutes modifications ou améliorations qui pourraient y être apportées ultérieurement.</p>
                                        <p>CHECKMYBP concède au Client, dans les conditions et limites stipulées aux présentes, un droit d’utilisation non-exclusif et non transférable desdits Progiciels pour la durée prévue à l’Article 5.</p>
                                        <p>CHECKMYBP se réserve le droit exclusif d’intervenir sur les Progiciels mis à disposition pour leur permettre d’être utilisés conformément à leur destination et notamment pour en corriger les éventuelles erreurs. Le Client s’interdit en conséquence formellement d’intervenir ou de faire intervenir un tiers sur ces derniers. Le Client n’a pas l’autorisation de décompiler ou d’accéder aux codes-source, de faire décompiler ou de permettre à un tiers d’’accéder aux codes-sources des Progiciels. Le Client s’interdit de procéder à des opérations d’ingénierie inverse des Progiciels objets du présent contrat.</p>
                                        <p>CHECKMYBP se réserve également le droit de modifier à tout moment les fonctionnalités des Progiciels, sans que le Client ne puisse s’y opposer. CHECKMYBP est libre d’effectuer tout complément ou amélioration de ses Progiciels, et plus généralement toute modification destinée à en améliorer l’exécution.</p>
                                        <p>Il appartient au Client de prendre toutes les dispositions permettant une utilisation satisfaisante des Progiciels. Le Client est en outre responsable de la protection des données enregistrées au niveau de son système d’informations le cas échéant.</p>
                                        <p>
                                        En outre, le Client s’interdit notamment de : <br/>
                                        &nbsp;&nbsp;&nbsp;–&nbsp;&nbsp;enfreindre les exigences, procédures, règles ou règlementations des réseaux connectés au Site ou aux Progiciels ; <br/>
                                        &nbsp;&nbsp;&nbsp;–&nbsp;&nbsp;tenter de porter atteinte à tout Client ou prospect de CHECKMYBP, hébergeur ou réseau, ce qui comprend, sans exhaustivité aucune, le fait, volontairement ou par négligence, d’exposer le Site Internet ou les Progiciels à un virus, de créer une saturation ou encore d’inonder le serveur ; <br/>
                                        &nbsp;&nbsp;&nbsp;–&nbsp;&nbsp;tenter d’accéder aux données qui ne sont pas destinées au Client ou d’entrer dans un serveur/un compte auquel le Client n’est pas autorisé à avoir accès ; <br/>
                                        &nbsp;&nbsp;&nbsp;–&nbsp;&nbsp;tenter de sonder, scruter ou de tester la vulnérabilité d’un système ou d’un réseau, ou encore d’enfreindre les mesures de sécurité ou d’authentification sans en avoir reçu l’autorisation ; <br/>
                                        &nbsp;&nbsp;&nbsp;–&nbsp;&nbsp;usurper l’identité ou les identifiants d’un autre Client.<br/>
                                        </p>
                                        <br/>
                                        <h2>ARTICLE 4 – MODALITES D’UTILISATION </h2>
                                        <p>Afin d’utiliser le Service et les Progiciels, le Client doit créer un Compte Client sur le Site Internet et s’authentifier au moyen des Identifiants personnels de ce Compte.</p>
                                        <p>A cette fin, il doit renseigner une adresse email valide et un mot de passe, ainsi que ses coordonnées, nom de son projet (si l’entreprise n’est pas déjà créée) ou de son entreprise.</p>
                                        <p>Les Identifiants sont strictement personnels et confidentiels à chaque Client. Il s’interdit de les communiquer à des tiers et s’engage à mettre tout en œuvre pour maintenir leur confidentialité. Tout accès par son compte Client aux Progiciels, sera de la responsabilité de ce Client, sans recours possible contre CHECKMYBP.</p>
                                        <p>A l’issue de la création du Compte Client, le Client recevra un email de confirmation de création de son Compte Client. Il recevra dans ce mail un lien hypertexte « d’activation » lui permettant d’activer son Compte client. Tant que le Compte Client n’est pas activé, le Client ne pourra pas accéder aux Progiciels.</p>
                                        <p>En cas de non-activation ou perte de mot de passe, le Client pourra contacter CHECKMYBP par mail à l’adresse info@checkmybp.fr. CHECHMYBP lui indiquera alors la marche à suivre pour réactiver son compte.</p>
                                        <br />
                                        <h2>ARTICLE 5 – DUREE</h2>
                                        <p>L’utilisation des Progiciels est effective pour une période maximale de 6 (six) mois dès activation du Compte Client. Le Client peut alors modifier autant de fois qu’il le souhaite ses prévisions financières, jusqu’à la validation de leur cohérence souhaitée. Au-delà du sixième mois plus un jour, l’ensemble des données liées à ses prévisions sont effacées. Le Client ne peut alors accéder aux Progiciels. Le cas échéant, il devra alors de nouveau réactiver son compte Client s’il souhaite utiliser de nouveau les Progiciels.</p>
                                        <br />
                                        <h2>ARTICLE 6 – CONDITIONS FINANCIERES </h2>
                                        <p>L’utilisation des Progiciels est consentie par CHECKMYBP au Client contrepartie du règlement de la somme de 100 (cent) euros HT par création et utilisation d’un (1) unique Compte Client visant à profiter du Service pour un (1) unique business plan.</p>
                                        <p>La contrepartie est due au moment de la création du Compte Client, soit avant l’accès aux Progiciels. </p>
                                        <p>Cette contrepartie est à acquitter par entité juridique et/ou personne physique utilisatrice (le Client) des Progiciels et par business plan. </p>
                                        <p>Chaque entité juridique ou personne physique est associée à un Compte Client distinct.</p>
                                        <p>Le Client souhaitant valider plusieurs business plans relatifs à des entités juridiques distinctes de son groupe, devra activer autant de Compte Client et s’acquitter de la contrepartie à hauteur de cent (100) euros HT pour chaque Compte Client activé.</p>
                                        <p>La mise à disposition des Progiciels est subordonnée à la réception préalable du paiement de la contrepartie par CHECKMYBP.</p>
                                        <p>Le paiement de la contrepartie se fait via carte bancaire ou carte de débit. A cet effet, CHECKMYBP utilise la plateforme de traitement de paiements en ligne STRIPE (dont les conditions d’utilisation et politique de confidentialité sont accessibles à l’adresse suivante : https://stripe.com/fr).</p>
                                        <p>Aucune résiliation anticipée du fait du Client ou de CHECKMYBP, dans les conditions prévues à l’Article 10, ne donne droit au Client à un remboursement de la contrepartie versée à CHECKMYBP.</p>
                                        <br/>
                                        <h2>ARTICLE 7 – DROITS DE PROPRIETE INTELLECTUELLE </h2>
                                        <p>CHECKMYBP garantit au Client qu’il détient l’intégralité des droits lui permettant de proposer l’utilisation des Progiciels au Client. </p>
                                        <p>Le Client s’engage à ne pas porter atteinte directement, indirectement ou par l’intermédiaire de tiers auxquels il serait associé, au droit de propriété intellectuelle de CHECKMYBP.</p>
                                        <p>En conséquence, le Client prendra toutes les mesures nécessaires à la protection desdits droits. A ce titre, il maintiendra notamment toutes les mentions de propriété portées sur les éléments constitutifs des Progiciels. Le Client s’engage à prendre à l’égard de son personnel et de toutes personnes extérieures qui auraient accès aux Progiciels, toutes les mesures nécessaires pour assurer le secret, la confidentialité et le respect des droits de propriété intellectuelle sur lesdits Progiciels. </p>
                                        <p>Le Client s’interdit toute utilisation des Progiciels non explicitement autorisée par les présentes. Toute extension du droit d’utilisation non prévue aux présentes sera soumise à l’autorisation écrite préalable de CHECKMYBP et devra faire l’objet d’un avenant par écrit. En cas de non respect des CGV, outre tous les autres recours que CHECKMYBP pourrait avoir, le Client devra à CHECKMYBP une somme au moins égale aux redevances qui auraient dû être versées à CHECKMYBP par toute tierce personne ayant utilisé les Progiciels.</p>
                                        <p>L’acceptation expresse des CGV n’implique aucune cession au profit du Client des droits de propriété intellectuelle détenus par CHECKMYBP sur le Service et les Progiciels.</p>
                                        <p>En conséquence, et sauf autorisation écrite et préalable de CHECKMYBP, le Client s’interdit formellement de reproduire et/ou installer les Progiciels, en tout ou partie, par tout moyen et sous toute forme, de façon permanente ou provisoire, sur tout autre équipement lui appartenant ou non.</p>
                                        <br/>
                                        <h2>ARTICLE 8 – COMMUNICATION </h2>
                                        <p>Le Client autorise CHECKMYBP à faire état de leur collaboration sur tout support promotionnel de son choix tels que, mais sans se limiter, le Site Internet, les réseaux sociaux affiliés à CHECKMYBP ainsi que toute communication promotionnelle à destination du public, et ce par tout moyen de communication actuel ou futur.</p>
                                        <p>A cette fin, le Client concède à CHECKMYBP une licence à titre gratuit de reproduction de son logo sur le Site Internet, les réseaux sociaux affiliés à CHECKMYBP mais aussi pour toute communication promotionnelle à destination du public, et ce par tout moyen de communication actuel ou futur, dans l’unique objectif de mettre en avant la relation commerciale entre le Client et CHECKMYBP. </p>
                                        <p>Toutefois, le Client peut s’opposer à cette licence en communiquant à CHECKMYBP un écrit préalable à la création du Compte Client à l’attention de CHECKMYBP via email à l’adresse suivante : info@checkmybp.fr informant CHECKMYBP de son refus que CHECKMYBP utilise son logo et/ou son nom aux fins de mise en avant de leur relation commerciale. </p>
                                        <br />
                                        <h2>ARTICLE 9 – RESPONSABILITE</h2>
                                        <p>CHECKMYBP s’engage à faire ses meilleurs efforts pour assurer l’accès et la disponibilité des Progiciels et en limiter les perturbations en cas de maintenance ou mises à jour. CHECKMYBP met tout en œuvre pour que le Site Internet soit disponible à chaque instant en Europe. Toutefois compte tenu, notamment, des besoins de maintenance, il peut arriver que le Service soit momentanément indisponible. CHECKMYBP ne saurait en aucun cas être tenu responsable des inconvénients éventuels résultant de l’indisponibilité du Site Internet. </p>
                                        <p>En cas de désactivation du Compte Client, dans les conditions prévues à l’Article 10, suite au non-respect des obligations du Client ou l’expiration de la durée d’utilisation des Progiciels, ce dernier ne pourra se retourner contre CHECKMYBP ; CHECKMYBP ne sera pas tenu de le rembourser, y compris s‘il souhaite de nouveau accéder aux Progiciels après l’expiration de la durée d’utilisation des Progiciels. Il devra alors créer un nouveau Compte Client.</p>
                                        <p>CHECKMYBP ne pourra être tenu responsable des préjudices subis par le Client du fait de l’utilisation de ses Progiciels.</p>
                                        <p>Le Client utilise les Progiciels et les résultats obtenus par sa mise en œuvre sous sa responsabilité exclusive, sans recours possible contre CHECKMYBP. Notamment, la responsabilité de CHECKMYBP ne saurait être engagée à raison d’erreurs, quelle qu’en soit la cause, dans les résultats obtenus ou transmis, qu’il appartient au Client de vérifier.</p>
                                        <p>Les informations renseignées par le Client restent sous son entière responsabilité. Les critères de validation de cohérence des prévisions financières retenus par CHECKMYBP, n’expriment qu’une appréciation subjective de cette dernière. CHECKMYBP ne pourra être tenue responsable de la non-obtention de financements par le Client ou de remise en cause de la pertinence d’analyse fournie par les Progiciels. Chaque intervenant tiers analysant les données renseignées par le Client, garde sa propre analyse sur la cohérence du business-plan du Client et donc son libre arbitre.</p>
                                        <p>CHECKMYBP ne saurait de même être tenue responsable de la destruction accidentelle des données du Client enregistrées dans son système d’informations.</p>
                                        <p>En toute hypothèse, la responsabilité de CHECKMYBP est limitée à un montant égal à deux fois le prix payé par le Client pour utiliser les Progiciels.</p>
                                        <br/>
                                        <h2>ARTICLE 10 – RESILIATION</h2>
                                        <p>Les CGV pourront être résiliées de plein droit et sans formalités par CHECKMYBP à tout moment en cas de manquement par le Client à l’une quelconque de ses obligations prévues aux présentes. </p>
                                        <p>Le Client peut également résilier son compte Client à tout moment. </p>
                                        <p>Toutefois, CHECKMYBP n’effectuera aucun remboursement total ou partiel de la contrepartie versée par le Client. </p>
                                        <p>En tout état de cause, le Compte Client sera résilié automatiquement à l’expiration de la période précisée à l’Article 5 (Durée) des présentes.</p>
                                        <br />
                                        <h2>ARTICLE 11 – RENSEIGNEMENTS ET RECLAMATIONS</h2>
                                        <p>Tout demande de renseignement et/ou réclamation relatives au Service devront être adressées par le Client à CHECKMYBP (i) par courriel à l’adresse suivante : info@checkmybp.fr, ou par lettre recommandée avec accusé de réception à l’adresse suivante : CHECKMYBP SAS, 63 BIS RUE DE L’YSER 92330 SCEAUX plus tard un (1) mois après la survenance de l’évènement objet de la réclamation. Une telle réclamation devra être accompagnée de l'ensemble des pièces justificatives y afférentes.</p>
                                        <br/>
                                        <h2>ARTICLE 12 – PROTECTION DES DONNEES A CARACTERE PERSONNEL </h2>
                                        <p>CHECKMYBP s’engage à prendre toutes les précautions nécessaires pour maintenir la confidentialité des informations de toute nature transmise par le Client dont CHECKMYBP aurait eu connaissance à l’occasion de l’exécution des CGV.</p>
                                        <p>
                                            CHECKMYBP peut déposer des cookies sur l’ordinateur ou appareils électroniques du Client lors de l’utilisation de son site ou de ses Progiciels.<br/>
                                            Ces cookies sont utilisés pour collecter des informations sur la manière dont le Client intéragit avec le Site Internet ou ses Progiciels. <br/>
                                            CHECKMYBP utilise ces informations afin d’améliorer et de personnaliser l’expérience de navigation du Client.<br/>
                                        </p>
                                        <p>CHECKMYBP s’engage à respecter la réglementation en vigueur applicable au traitement de données à caractère personnel et, en particulier, le règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 applicable à compter du 25 mai 2018 (ci-après le &laquo; RGPD &raquo;).</p>
                                        <p>Dans le cadre de l’exécution des présentes, CHECKMYBP est amenée à traiter des données personnelles du Client. Elle endosse en conséquence la qualité de responsable de traitement. </p>
                                        <p>A cet effet, il est précisé ce qui suit :</p>
                                        <br/>
                                        <h3>12.1&nbsp;&nbsp;Finalités du traitement</h3>
                                        <p>Le Client est informé, et s’engage à informer ses salariés, le cas échéant, du fait que CHECKMYBP met en œuvre des traitements de données à caractère personnel afin de lui permettre d’assurer la gestion, la facturation, l’utilisation de ses Progiciels et le suivi de sa relation contractuelle avec le Client. Ces traitements ont également pour finalité le respect des obligations légales et règlementaires de CHECKMYBP à cet effet.</p>
                                        <br/>
                                        <h3>12.2&nbsp;&nbsp;Données collectées</h3>
                                        <p>Les données à caractère personnel objet de ce traitement sont les prénom, nom, courriel, numéro de téléphone, données de facturation, code postal des personnes physiques représentant le Client, en ce compris ses salariés le cas échéant, avec lesquels CHECKMYBP sera en contact dans le cadre de la réalisation du Service.</p>
                                        <br/>
                                        <h3>12.3&nbsp;&nbsp;Fondements et destinataires</h3>
                                        <p>
                                            Le traitement des données personnelles est nécessaire aux fins d’exécution de la relation contractuelle entre le Client et CHECKMYBP.<br/>
                                            Les données traitées sont destinées, en fonction des nécessités, aux services habilités de CHECKMYBP, aux services chargés du contrôle (expert-comptable notamment) et aux sous-traitants éventuels de CHECKMYBP (tel que Stripe). Peuvent également être destinataires des données à caractère personnel : les organismes publics, exclusivement pour répondre aux obligations légales de CHECKMYBP.
                                        </p>
                                        <br/>
                                        <h3>12.4&nbsp;&nbsp;Absence de cession et transferts</h3>
                                        <p>Les données à caractère personnel collectées par CHECKMYBP ne sont pas destinées à faire l’objet de cession, location ou échange au bénéfice de tiers. Elles peuvent cependant être communiquées aux éventuels partenaires de CHECKMYBP chargés du traitement, de la gestion et du paiement du Service. </p>
                                        <br/>
                                        <h3>12.5&nbsp;&nbsp;Exercice des droits des personnes concernées</h3>
                                        <p>Le Client dispose d’un droit d’accès, de rectification, d’opposition, de limitation (c'est à dire que ses données soient temporairement rendues inaccessibles), d’effacement de ses données personnelles, ainsi qu’un droit à leur portabilité. </p>
                                        <p>En exerçant son droit à la portabilité, le Client peut choisir entre (i) récupérer les données personnelles la concernant qu'il a fournies à CHECKMYBP ou (ii) les transmettre à un autre responsable de traitement, dans la mesure où le traitement de ces données est effectué à l'aide de processus automatisés. La remise ou la transmission se fera dans un format structuré, couramment utilisé et lisible par machine.</p>
                                        <p>Pour exercer ces droits, le Client est tenu d’adresser un courriel à CHECKMYBP à l’adresse suivante : info@checkmybp.fr. Chaque demande doit être accompagnée d’un justificatif d’identité. En cas de doute raisonnable quant à l’identité du Client, il pourra lui être demandé des informations supplémentaires nécessaires pour confirmer son identité.</p>
                                        <p>En cas de demandes manifestement infondées, excessives ou répétées, CHECKMYBP se réserve le droit de refuser d’y donner suite.</p>
                                        <p>Après accusé réception de la demande du Client à CHECKMYBP dans le mois suivant la demande et faute de réponse favorable de CHECKMYBP dans un délai de trois (3) maximum à compter de la date de la demande, le Client est informé qu’il a le droit de saisir la CNIL d’une réclamation relative à ses données personnelles via la plateforme de plaintes en ligne de la CNIL accessible à l’adresse suivante : https://www.cnil.fr/fr/plaintes et/ou par courrier postal à l’adresse suivante : CNIL - Commission nationale de l'informatique et des libertés, 3 Place de Fontenoy, TSA 80715, 75334 PARIS CEDEX 07. </p>
                                        <br/>
                                        <h2>ARTICLE 13– INTEGRALITE DES CGV</h2>
                                        <p>Les présentes CGV, et les conditions particulières, le cas échéant, constituent l’intégralité de l’accord intervenu entre les Parties. Ce dernier prévaut sur tout autre accord qui aurait pu être conclu antérieurement entre les Parties.</p>
                                        <br/>
                                        <h2>ARTICLE 14 – MODIFICATION </h2>
                                        <p>CHECKMYBP se réserve la faculté de modifier à tout moment les CGV. Elle se réserve notamment la possibilité de modifier et/ou d’arrêter de proposer tout ou partie du Service, sous la seule réserve que cette interruption n’intervienne pas alors que le Service concerné n’est pas achevé.</p>
                                        <p>Toute modification des CGV sera notifiée au Client via son Compte Client et/ou via un encart sur le Site Internet et/ou via courriel à l’adresse email affiliée au Compte Client du Client. </p>
                                        <p>Les CGV applicables sont celles en vigueur au moment de la signature des CGV faisant acte de début de la souscription au Service. </p>
                                        <br/>
                                        <h2>ARTICLE 15 – INCESSIBILITE</h2>
                                        <p>Sauf autorisation préalable et écrite de CHECKMYBP, la présente acceptation des CGV par le Client, ne pourra faire l’objet d’une cession totale ou partielle du fait du Client.</p>
                                        <p>En cas d’acquisition de la société CHECKMYBP par une tierce partie ou de fusion entre CHECKMYBP et une tierce partie, CHECKMYBP se réserve le droit de céder tout ou partie de ses droits et obligations au titre des présentes conditions d’utilisation à la dite tierce partie ou à la nouvelle entité résultant de la fusion.</p>
                                        <br/>
                                        <h2>ARTICLE 16 – LOI APPLICABLE ET TRIBUNAUX COMPETENTS </h2>
                                        <p>
                                            Les CGV sont régies par la loi française. <br/>
                                            En cas de contestation sur la validité, l’interprétation et/ou l’exécution des CGV, les Parties conviennent que les tribunaux de Paris seront exclusivement compétents pour en juger. 
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(CGU);
