import { axiosInstance } from '../axios';
import env from '../../env';

const initBP = data => {
    localStorage.setItem('initBP', `{"name":"${data.name}","type":"${data.type}", "innovant":"${data.innovant}", "project_created": "${data.project_created}"}`);
};

// Save new BP
const newBP = data => {
    return axiosInstance
        .post(env.REACT_APP_API_URL + '/business-plan', {
            name: JSON.parse(localStorage.getItem('initBP')).name,
            key_value: data[Object.keys(data)[0]],
            type: JSON.parse(localStorage.getItem('initBP')).type,
            status: 1,
            innovant: JSON.parse(localStorage.getItem('initBP')).innovant,
            project_created: JSON.parse(localStorage.getItem('initBP')).project_created,

        })
        .then(response => {
            localStorage.removeItem('initBP');
            localStorage.removeItem('keyPayment');
            localStorage.removeItem('code_reduction');
            if (response.status === 400 || response.status === 500) throw response.data;
            return response.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export { initBP, newBP };
