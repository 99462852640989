import React, { Component } from 'react';

import { Row, Col, Card, CardBody, Label, Button, Alert } from 'reactstrap';
// import { Link } from 'react-router-dom';

import avatar1 from '../../../assets/images/users/avatar-0.png';
import profileImg from '../../../assets/images/profile-img.png';
import { axiosInstance } from '../../../helpers/axios';
import { AvForm, AvField, AvRadio, AvRadioGroup } from 'availity-reactstrap-validation';
import env from '../../../env';


class InfoPart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user,
            typeUser: [],
            loaded: false,
            display: true,
            edit: false,
            delete: false,
            errorMessage: null,
            textMessage: null
        };
        this.updateUser = this.updateUser.bind(this);
    }

    updateUser(event, values) {
        this.setState({errorMessage:false, textMessage: null });
        if(values.phone != null && values.phone != undefined && values.phone != "")
            if(!values.phone.match(/^[\+]?[0-9\s]{3,24}$/g))
                this.setState({errorMessage:true, textMessage: "Le numéro de téléphone ne respecte pas un format autorisé" }); 
        if(this.state.errorMessage == false)
            axiosInstance
            .put(env.REACT_APP_API_URL + '/user/' + this.state.user._id, values)
            .then(res => {
                this.setState({
                    display: true, edit: false, delete: false, loaded: true,
                    user: {
                        "_id": this.state.user._id,
                        "_login": this.state.user._login,
                        "_email": this.state.user._email,
                        "_type_user": values.type_user,
                        "_civilite": values.civilite,
                        "_nom": values.nom,
                        "_prenom": values.prenom,
                        "_adresse": values.adresse,
                        "_code_postal": values.code_postal,
                        "_ville": values.ville,
                        "_phone": values.phone
                    }
                });
            })
            .catch(err => {
                console.log(err)
            });
    }

    componentDidMount() {
        axiosInstance
            .get(env.REACT_APP_API_URL + '/user/type/all')
            .then(res => {
                this.setState({ typeUser: res.data, loaded: true });
            })
            .catch(err => {
                this.setState({ errorMessage: true, textMessage: "L'enregistrement de l'utilisateur a échoué" })
            });
    }

    render() {
        const { user } = this.state;
        return (
            <React.Fragment>
                <Card className='overflow-hidden'>
                    <div className='bg-soft-primary'>
                        <Row>
                            <Col xs='7'>
                                <div className='text-primary p-3'>
                                    <h5 className='text-primary'>Informations</h5>
                                    <p>CheckMyBP.fr</p>
                                </div>
                            </Col>
                            <Col xs='5' className='align-self-end'>
                                <img src={profileImg} alt='' className='img-fluid' />
                            </Col>
                        </Row>
                    </div>
                    <div className='avatar-md profile-user-wid mb-4 offset-1'>
                        <img src={avatar1} alt='' className='img-thumbnail rounded-circle' />
                    </div>
                    {this.state.errorMessage && (
                        <Alert color='danger'>{this.state.textMessage}</Alert>
                    )}
                    {this.state.display && this.state.loaded &&
                        (<CardBody className='pt-0'>
                            <Row>
                                <Col sm='12'>
                                    <Row>
                                        <Col className='label-key' lg={2} >
                                            <Label >Identifiant: </Label>
                                        </Col>
                                        <Col className='label-value' lg={10}>
                                            <Label >{user._login}</Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='label-key' lg={2} >
                                            <Label >Email: </Label>
                                        </Col>
                                        <Col className='label-value' lg={10}>
                                            <Label >{user._email}</Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='label-key' lg={2} >
                                            <Label >Profil: </Label>
                                        </Col>
                                        {this.state.loaded
                                            ? (
                                                <Col className='label-value' lg={10}>
                                                    <Label >{this.state.typeUser.find(x => x.id == user._type_user).nom}</Label>
                                                </Col>
                                            ) : (<Col className='label-value' lg={10}>
                                                <Label ></Label>
                                            </Col>)
                                        }
                                    </Row>
                                    <Row>
                                        <Col className='label-key' lg={2} >
                                            <Label >Civilité: </Label>
                                        </Col>
                                        <Col className='label-value' lg={10}>
                                            <Label >{user._civilite}</Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='label-key' lg={2} >
                                            <Label >Nom: </Label>
                                        </Col>
                                        <Col className='label-value' lg={10}>
                                            <Label >{user._nom}</Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='label-key' lg={2} >
                                            <Label >Prénom: </Label>
                                        </Col>
                                        <Col className='label-value' lg={10}>
                                            <Label >{user._prenom}</Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='label-key' lg={2} >
                                            <Label >Adresse: </Label>
                                        </Col>
                                        <Col className='label-value' lg={10}>
                                            <Label >{user._adresse}</Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='label-key' lg={2} >
                                            <Label >CP: </Label>
                                        </Col>
                                        <Col className='label-value' lg={10}>
                                            <Label >{user._code_postal}</Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='label-key' lg={2} >
                                            <Label >Ville: </Label>
                                        </Col>
                                        <Col className='label-value' lg={10}>
                                            <Label >{user._ville}</Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='label-key' lg={2} >
                                            <Label >Téléphone: </Label>
                                        </Col>
                                        <Col className='label-value' lg={10}>
                                            <Label >{user._phone}</Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='label-key' lg={2} >
                                            <Label >Partenaire: </Label>
                                        </Col>
                                        <Col className='label-value' lg={10}>
                                            <Label >{user._partenaire}</Label>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row >
                                <Col sm='3 offset-2'>
                                    <Button
                                        color='primary'
                                        onClick={() => this.setState({ display: false, delete: false, edit: true })}
                                    >
                                        Modifier mon profil
                                    </Button>
                                </Col>
                                {/* <Col sm='4 offset-1'>
                                    <Button
                                        color='primary'
                                        onClick={() => this.setState({})}
                                    >
                                        Supprimer mon profil
                                    </Button>
                                </Col> */}
                            </Row>
                        </CardBody>
                        )}
                    {this.state.edit && this.state.loaded && (
                        <CardBody className='pt-0'>
                            <AvForm
                                className='form-horizontal'
                                onValidSubmit={(e, v) => {
                                    this.updateUser(e, v);
                                }}
                                model={user}
                            >
                                <AvField
                                    label='Type utilisateur'
                                    type='select'
                                    name='type_user'
                                    value={this.state.typeUser.find(x => x.id == user._type_user).id}
                                    required
                                >
                                    {this.state.typeUser.map((obj, index) => {
                                        return (
                                            <option
                                                name='type_user'
                                                key={index}
                                                value={obj.id}
                                            >
                                                {obj.nom}
                                            </option>
                                        );
                                    })}
                                </AvField>
                                <AvRadioGroup inline name='civilite' label='Civilité' value={user._civilite} required>
                                    <AvRadio customInput label='Madame' value='madame' />
                                    <AvRadio customInput label='Monsieur' value='monsieur' />
                                </AvRadioGroup>
                                <AvField
                                    name='nom'
                                    label='Nom'
                                    type='text'
                                    value={user._nom}
                                    required
                                    errorMessage='Champ invalide'
                                />
                                <AvField
                                    name='prenom'
                                    label='Prénom'
                                    type='text'
                                    value={user._prenom}
                                    required
                                    placeholder='Entrez votre prénom'
                                    errorMessage='Champ invalide'
                                />
                                <AvField
                                    name='adresse'
                                    label='Adresse'
                                    type='text'
                                    value={user._adresse}
                                    required
                                    placeholder='Entrez votre adresse de résidence'
                                    errorMessage='Champ invalide'
                                />
                                <AvField
                                    name='code_postal'
                                    label='Code Postal'
                                    type='text'
                                    required
                                    validate={{
                                        required: {
                                            value: false,
                                            errorMessage: 'Merci de renseigner un code postal valide 1'
                                        },
                                        pattern: {
                                            value: '/[0-9]{5}/',
                                            errorMessage: 'Merci de renseigner un code postal valide 2'
                                        }
                                    }}
                                    value={user._code_postal}
                                />
                                <AvField
                                    name='ville'
                                    label='Ville'
                                    type='text'
                                    value={user._ville}
                                    required
                                    placeholder='Entrez votre ville'
                                    errorMessage='Champ invalide'
                                />
                                <AvField
                                    name='phone'
                                    label='Téléphone'
                                    type='text'
                                    placeholder='061223445566 ou +3361223445566'
                                    maxLength='25'
                                    value={user._phone}
                                />
                                <AvField
                                    name='partenaire'
                                    label='Partenaire'
                                    type='text'
                                    value={user._partenaire}
                                    placeholder="Si vous venez de la part d'un partenaire, indiquez le ici"
                                />
                                <Row >
                                    <Button className='offset-4' type='submit' color='success'>
                                        Mettre à jour
                                    </Button>
                                    <Button className='offset-1' color='warning' onClick={() => this.setState({ display: true, delete: false, edit: false })}>
                                        Annuler
                                    </Button>
                                </Row>
                            </AvForm>
                        </CardBody>
                    )}
                </Card>
            </React.Fragment >
        );
    }
}

export default InfoPart;
