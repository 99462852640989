import axios from 'axios';
import env from '../../env';

// Login User
const postlogin = (url, data) => {
    console.log(env);
    return axios
        .post(env.REACT_APP_API_URL + url, data, {
            'Access-Control-Allow-Origin': '*'
        })
        .then(response => {
            if (response.status === 400 || response.status === 500) throw response.data;
            return response.data;
        })
        .catch(err => {
            var message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 401:
                        message = 'Mauvaise combinaison email / mot de passe';
                        break;
                    case 500:
                        message = 'Sorry! something went wrong, please contact our support team';
                        break;
                    default:
                        message = err[1];
                        break;
                }
            }
            throw message;
        });
};

// Forgot Pasword User
const forgotPassword = (url, data) => {
    return axios
        .get(env.REACT_APP_API_URL + url, data)
        .then(response => {
            if (response.status === 400 || response.status === 500) throw response.data;
            return response.data;
        })
        .catch(err => {
            var message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 401:
                        message = 'Mauvaise combinaison email / mot de passe';
                        break;
                    case 500:
                        message = 'Sorry! something went wrong, please contact our support team';
                        break;
                    default:
                        message = err[1];
                        break;
                }
            }
            throw message;
        });
};

const renewPassword = (url, data) => {
    return axios
        .put(env.REACT_APP_API_URL + url, data, {
            'Access-Control-Allow-Origin': '*'
        })
        .then(response => {
            if (response.status === 400 || response.status === 500) throw response.data;
            return response.data;
        })
        .catch(err => {
            var message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 401:
                        message = 'Mise à jour du mot de passe nont autorisée';
                        break;
                    case 500:
                        message = 'Sorry! something went wrong, please contact our support team';
                        break;
                    default:
                        message = err[1];
                        break;
                }
            }
            throw message;
        });
};

export { postlogin, forgotPassword, renewPassword };
