import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    // Alert,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardText,
    CardSubtitle,
    Col,
    FormGroup,
    UncontrolledCollapse,
    Container,
    Row,
    Label,
    Input,
    Spinner
} from 'reactstrap';
// import classnames from 'classnames';
import { Link, Redirect } from 'react-router-dom';
// import { Redirect } from 'react-router-dom';

import { axiosInstance } from '../../helpers/axios';
import env from '../../env';

//Import Breadcrumb
import Breadcrumbs from '../../components/ThemeCMBP/Partials/Breadcrumb';

//i18n
import { withNamespaces } from 'react-i18next';

class viewResumeBusinessPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            archivedbp: null,
            user: null,
            isLoading: true,
        }
    }

    componentDidMount() {
        this.setState({ message: this.props.location?.state?.message });
        axiosInstance
            .get(env.REACT_APP_API_URL + '/user/me')
            .then(res => {
                this.setState({ user: res.data });
            })
            .then(res => {
                axiosInstance
                    .get(env.REACT_APP_API_URL + '/business-plan/archive/' + this.props.match.params.id)
                    .then(res => {
                        this.setState({ archivedbp: res.data, isLoading: false });
                    })
                    .catch(err => {
                        this.setState({ errorMessage: err.message });
                    });
            })
            .catch(err => {
                this.setState({ errorMessage: err.message });
            });
    }

    transcoStatus(idStatus) {
        if (idStatus === 1) {
            return 'missing data';
        } else if (idStatus === 2) {
            return 'warning';
        } else if (idStatus === 3) {
            return 'valid';
        } else if (idStatus === 4) {
            return 'invalid';
        } else if (idStatus === 5) {
            return 'init';
        } else if (idStatus === 6) {
            return 'working';
        }
    }

    render() {
        if (this.state.isLoading) {
            return <p>Loading ...</p>;
        }
        return (
            <React.Fragment>
                <div className='page-content'>
                    <Container fluid>
                        <Breadcrumbs
                            title={this.props.t('Résumé de votre business plan')}
                            breadcrumbItem='Résumé Business Plan'
                        />
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg={{ size: 4, offset: 8 }}>
                                        <Button margin-righ='0' onClick={() => window.print()} color='info' size='md' offset='4' block>
                                            Imprimer le résumé
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Container>
                    <Container fluid>
                        <Card>
                            <CardBody>
                                {Object.entries(this.state.archivedbp).map((item, i) =>
                                    item[0] != "Modules" ?
                                        (<Row>
                                            <Col className='label-key' lg={2} >
                                                <Label >{item[0].replaceAll("_", " ").replaceAll("$", "'")}</Label>
                                            </Col>
                                            <Col className='label-value' lg={10}>
                                                <Label >{item[1]}</Label>
                                            </Col>
                                        </Row>)
                                        :
                                        item[1].map((module, j) =>
                                            module !== null ?
                                                Object.entries(module).map((innerval, k) =>
                                                    innerval[0] == "Type" || innerval[0] == "Status" ?
                                                        (<Row>
                                                            <Col className='label-key' lg={2} >
                                                                <Label >{innerval[0].toString()}</Label>
                                                            </Col>
                                                            <Col className='label-value' lg={10}>
                                                                <Label >{innerval[1].toString()}</Label>
                                                            </Col>
                                                        </Row>)
                                                        :
                                                        (<Row>
                                                            <Col className='label-key' lg={2} >
                                                                <Label >{innerval[0].replaceAll("_", " ").replaceAll("$", "'")}</Label>
                                                            </Col>
                                                            {innerval[1].map((champ, l) => (<Col className={this.transcoStatus(Object.values(champ)[1])} lg={2}>{Object.values(champ)[0] == null ? "null" : Object.values(champ)[0]}</Col>))}
                                                        </Row>)
                                                )
                                                : (<Row>Test</Row>)
                                        )
                                )}
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment >)
    }
}

export default withNamespaces()(viewResumeBusinessPlan);
