import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { axiosInstance } from '../../helpers/axios';

import { Container, Row, Col, Card, CardBody, Table, Button } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import env from '../../env';

//i18n
import { withNamespaces } from 'react-i18next';
//Import Breadcrumb
import Breadcrumbs from '../../components/ThemeCMBP/Partials/Breadcrumb';
import moment from 'moment';
import I18next from 'i18next';
import BilanListBP from '../../components/ThemeCMBP/BusinessPlan/BilanListBP';
const currentLanguage = I18next.language;

class viewBusinessPlan extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bp: null,
            bp_name: null,
            bp_type: null,
            module_list: null,
            user: null,
            isLoading: true,
            errorMessage: null,
            redirect: false,
            isMessage: false,
            messageClass: null,
            message: null
        };
    }

    componentDidMount() {
        const {
            match: { params }
        } = this.props;
        if (this.props.location?.state?.isMessage) {
            this.setState({
                isMessage: this.props.location.state.isMessage,
                message: this.props.location.state.message,
                messageClass: this.props.location.state.messageClass
            });
        }
        axiosInstance
            .get(env.REACT_APP_API_URL + '/user/me')
            .then(res => {
                this.setState({ user: res.data });
            })
            .then(res => {
                axiosInstance
                    .get(env.REACT_APP_API_URL + '/business-plan/parsed/uuid/' + params.id)
                    .then(res => {
                        if (res.data._user._id !== this.state.user._id) {
                            this.setState({ redirect: true });
                        } else {
                            this.setState({
                                bp: res.data,
                                bp_name: res.data._name,
                                module_list: res.data._modules,
                                isLoading: false
                            });
                        }
                    })
                    .catch(err => {
                        this.setState({ errorMessage: err.message });
                    });
            })
            .catch(err => {
                this.setState({ errorMessage: err.message });
            });
    }

    render() {
        const { redirect } = this.state;

        if (redirect) {
            return (
                <Redirect
                    to={{
                        pathname: '/business-plan/list',
                        message: "vous n'avez pas les droits suffisants"
                    }}
                />
            );
        }
        if (this.state.isLoading) {
            return <p>Loading ...</p>;
        } else {
            const data = this.state;
            const module_list = this.state.module_list;
            return (
                <React.Fragment>
                    <div className='page-content'>
                        <Container fluid>
                            <Breadcrumbs
                                title={this.props.t('Liste des BP')}
                                breadcrumbItem='Bilan de votre Business Plan'
                            />

                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={8}>
                                            <h2>{data.bp_name}</h2>
                                            <p>
                                                Créé le :{' '}
                                                <b>
                                                    {new Intl.DateTimeFormat(currentLanguage, {
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: '2-digit'
                                                    }).format(moment(data.bp._key._date_activation).toDate())}
                                                </b>
                                                <br />
                                                Expire le :{' '}
                                                <b>
                                                    {new Intl.DateTimeFormat(currentLanguage, {
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: '2-digit'
                                                    }).format(moment(data.bp._key._date_expiration).toDate())}
                                                </b>{' '}
                                                <br />
                                                <p>identifiant unique : {data.bp._uuid_bp}</p>
                                            </p>
                                        </Col>
                                        <Col lg={4}>
                                            <Button onClick={() => window.print()} color='info' size='md' block>
                                                Imprimer le bilan
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg='12'>
                                            <div className=''>
                                                <div className='table-responsive'>
                                                    <Table className='project-list-table table-nowrap align-middle table-borderless table table-striped mb-0'>
                                                        <thead>
                                                            <tr>
                                                                <th
                                                                    scope='col'
                                                                    style={{
                                                                        textAlign: 'center',
                                                                        verticalAlign: 'middle'
                                                                    }}
                                                                >
                                                                    Nom du module
                                                                </th>
                                                                <th
                                                                    scope='col'
                                                                    style={{
                                                                        textAlign: 'center',
                                                                        verticalAlign: 'middle'
                                                                    }}
                                                                >
                                                                    Statut du module
                                                                </th>
                                                                <th
                                                                    scope='col'
                                                                    style={{
                                                                        textAlign: 'center',
                                                                        verticalAlign: 'middle'
                                                                    }}
                                                                >
                                                                    Donnée valide
                                                                </th>
                                                                <th
                                                                    scope='col'
                                                                    style={{
                                                                        textAlign: 'center',
                                                                        verticalAlign: 'middle'
                                                                    }}
                                                                >
                                                                    Donnée à surveiller
                                                                </th>
                                                                <th
                                                                    scope='col'
                                                                    style={{
                                                                        textAlign: 'center',
                                                                        verticalAlign: 'middle'
                                                                    }}
                                                                >
                                                                    Donnée invalide
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {module_list.map(obj => {
                                                                return (
                                                                    <BilanListBP
                                                                        key={'1'}
                                                                        name={obj}
                                                                        module={this.state.bp[obj]}
                                                                        uuid={this.state.bp._uuid_bp}
                                                                        description={this.state.bp}
                                                                    />
                                                                );
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }
    }
}
viewBusinessPlan.propTypes = {
    match: PropTypes.object
};

export default withNamespaces()(viewBusinessPlan);
