import React from 'react';
import { Redirect } from 'react-router-dom';

// Authentication related pages
/* import OnePage from "../pages/Others/onepage"; */
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';
import ForgetPwd from '../pages/Authentication/ForgetPassword';

// Dashboard
import Dashboard from '../pages/Dashboard/index';

// BusinessPlan
import indexBusinessPlan from '../pages/BusinessPlan/indexBusinessPlan';
import keyBusinessPlan from '../pages/BusinessPlan/keyBusinessPlan';
import newBusinessPlan from '../pages/BusinessPlan/newBusinessPlan';
import viewBusinessPlan from '../pages/BusinessPlan/viewBusinessPlan';
import viewModuleBP from '../pages/BusinessPlan/viewModule';
import paiementBusinessPlan from '../pages/BusinessPlan/paymentBusinessPlan';

// Modules
import indexModule from '../pages/Modules/indexModule';
import viewModule from '../pages/Modules/viewModule';

// User
import profile from '../pages/User/profile';
import facturation from '../pages/User/facturation';

// Others
import CGU from '../pages/Others/cgu';
import FAQ from '../pages/Others/faq';
import Vulgarisation from '../pages/Others/vulgarisation';
import page400 from '../pages/Others/page400';
import page500 from '../pages/Others/page500';

import Paiement from '../pages/Paiement/paiement';
import InitFactureVirement from '../pages/Authentication/InitFactureVirement';
import ConfirmFactureVirement from '../pages/Authentication/ConfirmFactureVirement';
import bilanBusinessPlan from '../pages/BusinessPlan/bilanBusinessPlan';
import viewArchiveBusinessPlan from '../pages/BusinessPlan/viewArchiveBusinessPlan';
import viewResumeBusinessPlan from '../pages/BusinessPlan/viewResumeBusinessPlan';

const authProtectedRoutes = [
    // { path: '', component: },

    // Dashboard
    { path: '/dashboard', component: Dashboard },

    // Business Plan
    { path: '/business-plan/list', component: indexBusinessPlan },
    { path: '/business-plan/new', component: newBusinessPlan },
    { path: '/business-plan/add-key', component: keyBusinessPlan },
    { path: '/business-plan/archive/:id', component: viewArchiveBusinessPlan },
    { path: '/business-plan/resume/:id', component: viewResumeBusinessPlan },
    { path: '/business-plan/view/:id', component: viewBusinessPlan },
    { path: '/business-plan/bilan/:id', component: bilanBusinessPlan },
    { path: '/business-plan/module/:id', component: viewModuleBP },
    { path: '/business-plan/paiement', component: paiementBusinessPlan },


    // Modules
    { path: '/modules/list', component: indexModule },
    { path: '/modules/view/:id', component: viewModule },

    // User
    { path: '/profile', component: profile },
    { path: '/factures', component: facturation },

    // Others
    { path: '/cgu', component: CGU },
    { path: '/faq', component: FAQ },
    { path: '/vulgarisation', component: Vulgarisation },
    
    { path: '/paiement', component: Paiement },
    
    { path: '/init-facture-virement', component: InitFactureVirement },
    { path: '/confirm-facture-virement', component: ConfirmFactureVirement },
    
    // this route should be at the end of all other routes
    { path: '/', exact: true, component: () => <Redirect to='/dashboard' /> }
];

const publicRoutes = [
    /* { path: "/", component: OnePage}, */
    { path: '/logout', component: Logout },
    { path: '/login', component: Login },
    { path: '/forgot-password', component: ForgetPwd },
    { path: '/register', component: Register },
    { path: '/400', component: page400 },
    { path: '/500', component: page500 },
    { path: '/cgu-disconnected', component: CGU },
];

export { authProtectedRoutes, publicRoutes };
