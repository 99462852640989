let env;
if (process.env.REACT_APP_API_URL) {
    env = {
        REACT_APP_API_URL: process.env.REACT_APP_API_URL,
        REACT_APP_DASHBOARD_URL: process.env.REACT_APP_DASHBOARD_URL
    };
} else {
    env = {
        REACT_APP_API_URL: '__REACT_APP_API_URL__',
        REACT_APP_DASHBOARD_URL: '__REACT_APP_DASHBOARD_URL__'
    };
}

export default env;
