import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import { axiosInstance } from '../../helpers/axios';
import env from '../../env';

//Import Breadcrumb
import Breadcrumbs from '../../components/ThemeCMBP/Partials/Breadcrumb';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

//i18n
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import I18next from 'i18next';
const currentLanguage = I18next.language;

class facturation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: null,
            data: null,
            isLoading: true,
            errorMessage: null,
            factureListColumns: [
                {
                    dataField: '_num_facture',
                    text: 'Référence de la facture',
                    sort: true,
                    formatter: (value, row) => <b>{value}</b>
                },
                {
                    dataField: '_nb_clef',
                    text: 'Nombre de clés',
                    sort: true,
                    formatter: (value, row) => <p>{value}</p>
                },
                {
                    dataField: '_date_creation',
                    text: 'Date de la facture',
                    sort: true,
                    formatter: (value, row) =>
                        new Intl.DateTimeFormat(currentLanguage, {
                            year: 'numeric',
                            month: 'long',
                            day: '2-digit'
                        }).format(moment(value).toDate())
                },
                {
                    dataField: '_type_paiement',
                    text: 'Méthode de paiement',
                    sort: true,
                    formatter: (value, row) => (value === 2 ? 'Virement' : 'Carte bancaire')
                },
                {
                    dataField: '_montant',
                    text: 'Montant de la facture',
                    sort: true,
                    formatter: (value, row) => <p>{value}.00 €</p>
                },
                {
                    dataField: '_id_type_facture',
                    text: 'Statut',
                    sort: true,
                    formatter: (value, facture) =>
                        facture._type_paiement === 1
                            ? 'Acquitée'
                            : facture._statut_paiement === 2
                            ? 'Acquitée'
                            : 'A payer'
                },
                {
                    dataField: 'menu',
                    text: 'Action',
                    formatter: (cellContent, facture) =>
                        facture._type_paiement === 1 ? (
                            <Button
                                href={'data:application/octet-stream;base64,' + facture._pdf}
                                className='btn btn-sm btn-info'
                                download={facture._num_facture + '.pdf'}
                            >
                                Télécharger
                            </Button>
                        ) : facture._statut_paiement === 2 ? (
                            <Button
                                href={'data:application/octet-stream;base64,' + facture._pdfA}
                                className='btn btn-sm btn-info'
                                download={facture._num_facture + '.pdf'}
                            >
                                Télécharger
                            </Button>
                        ) : (
                            <Button
                                href={'data:application/octet-stream;base64,' + facture._pdf}
                                className='btn btn-sm btn-info'
                                download={facture._num_facture + '.pdf'}
                            >
                                Télécharger
                            </Button>
                        )
                }
            ]
        };
    }

    componentDidMount() {
        axiosInstance
            .get(env.REACT_APP_API_URL + '/user/profile')
            .then(res => {
                this.setState({ user: res.data });
                axiosInstance
                    .get(env.REACT_APP_API_URL + '/billing/user/' + res.data.userId)
                    .then(res => {
                        this.setState({ data: typeof res.data !== 'object' ? [] : res.data, isLoading: false });
                    })
                    .catch(err => {
                        console.log(err);
                        this.setState({ errorMessage: err.message });
                    });
            })
            .catch(err => {
                console.log(err);
                this.setState({ errorMessage: err.message });
            });
    }

    render() {
        if (this.state.isLoading) {
            return <p>Loading ...</p>;
        } else if (this.state.isLoading) {
            return <p>Loading ...</p>;
        } else {
            const { data } = this.state;
            const { SearchBar } = Search;

            const pageOptions = {
                sizePerPage: 10,
                totalSize: data.length,
                custom: true
            };

            const defaultSorted = [
                {
                    dataField: 'id',
                    order: 'desc'
                }
            ];
            return (
                <React.Fragment>
                    <div className='page-content'>
                        <Container fluid>
                            <Breadcrumbs title='Tableau de bord' breadcrumbItem='Vos factures' />

                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <PaginationProvider
                                                pagination={paginationFactory(pageOptions)}
                                                keyField='id'
                                                columns={this.state.usersListColumns}
                                                data={data}
                                            >
                                                {({ paginationProps, paginationTableProps }) => (
                                                    <ToolkitProvider
                                                        keyField='id'
                                                        columns={this.state.factureListColumns}
                                                        data={data}
                                                        search
                                                    >
                                                        {toolkitprops => (
                                                            <React.Fragment>
                                                                <Row className='mb-2'>
                                                                    <Col sm='4'>
                                                                        <div className='search-box ms-2 mb-2 d-inline-block'>
                                                                            <div className='position-relative'>
                                                                                <SearchBar
                                                                                    placeholder='Rechercher...'
                                                                                    {...toolkitprops.searchProps}
                                                                                />
                                                                                <i className='bx bx-search-alt search-icon' />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xl='12'>
                                                                        <div className='table-responsive'>
                                                                            <BootstrapTable
                                                                                {...toolkitprops.baseProps}
                                                                                {...paginationTableProps}
                                                                                defaultSorted={defaultSorted}
                                                                                classes={
                                                                                    'table align-middle table-nowrap table-hover'
                                                                                }
                                                                                bordered={false}
                                                                                striped={false}
                                                                                responsive
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row className='align-items-md-center mt-30'>
                                                                    <Col className='pagination pagination-rounded justify-content-end mb-2'>
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolkitProvider>
                                                )}
                                            </PaginationProvider>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }
    }
}

export default withNamespaces()(facturation);
