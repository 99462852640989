import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from './actionTypes';
import { loginSuccess, apiError } from './actions';

//Helper file
import { postlogin } from '../../../helpers/auth/authController';

function* loginUser({ payload: { user, history } }) {
    try {
        const response = yield call(postlogin, '/auth/login', { username: user.username, password: user.password });
        localStorage.setItem('authUser', JSON.stringify(response));
        yield put(loginSuccess(response));
        history.push('/dashboard');
    } catch (error) {
        yield put(apiError(error));
    }
}

function* logoutUser({ payload: { history } }) {
    try {
        localStorage.removeItem('authUser');
        localStorage.removeItem('initBP');
        localStorage.removeItem('keyPayment');
        localStorage.removeItem('code_reduction');
        localStorage.removeItem('supervip');
        history.push('/login');
    } catch (error) {
        yield put(apiError(error));
    }
}

export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
    yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default authSaga;
