import axios from 'axios';
import env from '../env';

const baseUrl = env.REACT_APP_API_URL;

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    config => {
        if(localStorage.getItem('authUser') != undefined){
            const accessToken = JSON.parse(localStorage.getItem('authUser')).access_token;
            if (accessToken) {
                config.headers['Authorization'] = 'Bearer ' + accessToken;
            }    
        }
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    async function (error) {
        console.log("error : ",error)
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const access_token = await refreshAccessToken();
            axios.defaults.headers['Authorization'] = 'Bearer ' + access_token;
            return axiosInstance(originalRequest);
        }
        return Promise.reject(error);
    }
);

async function refreshAccessToken() {
    let refreshToken = JSON.parse(localStorage.getItem('authUser')).refresh_token;

    return axios
        .get(`${baseUrl}/auth/refresh`, {
            headers: {
                Authorization: `Bearer ${refreshToken}`
            }
        })
        .then(res => {
            console.log(res);
            if (res.status === 200) {
                const data = `{"access_token":"${res.data.access_token}","refresh_token":"${res.data.refresh_token}"}`;
                localStorage.setItem('authUser', data);
                return true;
            }
        })
        .catch(err => {
            console.log(err);
            localStorage.clear();
            window.location.replace('/login');
        });
}
export { axiosInstance };
