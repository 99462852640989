import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { AvForm, AvInput } from 'availity-reactstrap-validation';
import { Alert, Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import verificationImg from '../../assets/images/megamenu-img.png';

import { axiosInstance } from '../../helpers/axios';
import env from '../../env';

//Import Breadcrumb
import Breadcrumbs from '../../components/ThemeCMBP/Partials/Breadcrumb';

//i18n
import { withNamespaces } from 'react-i18next';

class paymentBusinessPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numberKey: 0,
            code_reduction:'',
            redirectPrev: false,
            user: null,
            env: env.REACT_APP_DASHBOARD_URL,
            api: env.REACT_APP_API_URL
        };
        this.handlePayment = this.handlePayment.bind(this);
    }

    handlePayment() {
        axiosInstance
            .get(env.REACT_APP_API_URL + '/user/me')
            .then(res => {
                this.setState({ user: res.data });
                axiosInstance
                    .post(env.REACT_APP_API_URL + '/payment/init', {
                        nb_key: JSON.parse(localStorage.getItem('keyPayment')).key,
                        code_remise: JSON.parse(localStorage.getItem('code_reduction')).code_reduction,
                        id_user: res.data._id,
                        id_facturation: JSON.parse(localStorage.getItem('id_facturation')).id_facturation,
                    })
                    .then(res => {
                        window.location.href = res.data.url;
                    })
                    .catch(err => {
                        console.log('err paiement', err);
                    });
            })
            .catch(err => {
                console.log(err);
            });
    }

    componentDidMount() {
        if (this.props.location.state.key !== JSON.parse(localStorage.getItem('keyPayment')).key) {
            this.setState({ redirectPrev: true });
        } else {
            this.setState({ numberKey: this.props.location.state.key });
        }
        if (this.props.location.state.code_reduction === JSON.parse(localStorage.getItem('code_reduction')).code_reduction)
            this.setState({ code_reduction: this.props.location.state.code_reduction });
    }

    render() {
        const { numberKey, code_reduction,  redirectPrev } = this.state;
        if (redirectPrev) {
            return (
                <Redirect
                    to={{
                        pathname: '/business-plan/add-key',
                        message: 'error'
                    }}
                />
            );
        }

        if (this.state.isLoading) {
            return <p>Loading ...</p>;
        } else {
            return (
                <React.Fragment>
                    <div className='page-content'>
                        <Container fluid>
                            <Breadcrumbs
                                title={this.props.t('Votre Business Plan')}
                                breadcrumbItem='Ajout de nouvelles clés'
                            />

                            <Row className='justify-content-center mt-lg-5'>
                                <Col xl='5' sm='8'>
                                    <Card>
                                        <CardBody>
                                            <div className='text-center'>
                                                <Row className='justify-content-center'>
                                                    <Col lg='10'>
                                                        <h4 className='mt-4 font-weight-semibold'>Zone de paiement</h4>
                                                        
                                                        {code_reduction != '' && code_reduction != null 
                                                        ?
                                                        <p className='text-muted mt-3'>
                                                            Vous êtes sur le point d'utiliser le bon de réduction '{code_reduction}'.
                                                            Si le bon de réduction est utilisable, assurez vous qu'il est bien appliqué au moment du paiment
                                                            (ex : Le bon de réduction est de 25% pour une clé. Dans ce cas le montant doit être de 90€).<br/>
                                                            Si le bon de réduction renseigné ci-dessus n'est pas utilisable, il vous sera demandé de  procéder au paiement de{' '}
                                                            {numberKey} {numberKey > 1 ? 'clés' : 'clé'} pour un montant
                                                            de {' ' + numberKey * 120}€ TTC. Sélectionnez votre mode de
                                                            paiement puis procédez au paiement. A la fin de votre
                                                            opération, vous serez redirigé vers notre site.
                                                        </p> 
                                                        :
                                                        <p className='text-muted mt-3'>
                                                            Vous êtes sur le point de procéder au paiement de{' '}
                                                            {numberKey} {numberKey > 1 ? 'clés' : 'clé'} pour un montant
                                                            de {' ' + numberKey * 120}€ TTC. Sélectionnez votre mode de
                                                            paiement puis procédez au paiement. A la fin de votre
                                                            opération, vous serez redirigé vers notre site.
                                                        </p>}
                                                        

                                                        <div className='mt-4'>
                                                            {this.props.error && this.props.error ? (
                                                                <Alert color='danger'>{this.props.error}</Alert>
                                                            ) : null}
                                                            <AvForm
                                                                className='form-horizontal'
                                                                onValidSubmit={this.handlePayment}
                                                            >
                                                                <AvInput
                                                                    name='nb_keys'
                                                                    value={numberKey}
                                                                    hidden
                                                                    required
                                                                />
                                                                <AvInput
                                                                    name='code_reduction'
                                                                    value={code_reduction}
                                                                    hidden
                                                                />
                                                                <Button type='submit' color='primary'>
                                                                    Procéder au paiement
                                                                </Button>
                                                            </AvForm>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className='justify-content-center mt-5 mb-2'>
                                                    <Col sm='6' xs='8'>
                                                        <div>
                                                            <img src={verificationImg} alt='' className='img-fluid' />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }
    }
}
paymentBusinessPlan.propTypes = {
    match: PropTypes.object
};

export default withNamespaces()(paymentBusinessPlan);
